import React, { useEffect, useState } from "react";
import "./Feed.css";

import { getMasjids } from "../../../Redux/Actions/MasjidActions/FetchingMasjids";
import { getStaleMasjids } from "../../../Redux/Actions/MasjidActions/FetchingStaleMasjids";
import { FetchingAdmins } from "../../../Redux/Actions/AdminActions/FetchingAdmins";
import EventsCarousel from "../EventsCarousel/EventsCarousel";
import { FetchingLatestUpdatedEvents } from "../../../Redux/Actions/EventsActions/FetchingLatestUpdatedEvents";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import DataBox from "../DataBox/DataBox";
import StaleMasjidTable from "../StaleMasjidTable/StaleMasjidTable";
import {
  App,
  MyProvider,
  PostsComponent,
  MasjidProfile
} from "@msa-software-llc/connectmazjid-vanilaportal";
import { useAppSelector, useAppThunkDispatch } from "../../../Redux/hooks";
import { Route, Routes } from "react-router-dom";

// import { useAppSelector, useAppThunkDispatch } from "../../../Redux/hooks";

export type AdminInterFace = {
  autoPrefillingTiming: boolean;
  email: string;
  isVerified: boolean;
  masjids: any[];
  name: string;
  role: string;
  _id: string;
};
const Feed = () => {
  const masjids = useAppSelector((state) => state.masjids);
  const admins = useAppSelector((state) => state.allAdmins);
  const staleMasjids = useAppSelector((state) => state.staleMasjids);
  const [Loading, setLoading] = useState(false);
  const dispatch = useAppThunkDispatch();
  // const admin = JSON.parse(localStorage.getItem("admin"));

  const adminString = localStorage.getItem("admin");
  const admin: AdminInterFace | null = adminString
    ? JSON.parse(adminString)
    : null;

  useEffect(() => {
    if (admin?.role === "superadmin" || admin?.role === "admin") {
      setLoading(true);
      masjids.length === 0 && dispatch(getMasjids("createdAt", "asc"));
      admins.length === 0 && dispatch(FetchingAdmins());
      let res = dispatch(FetchingLatestUpdatedEvents());
      res.then((result: any) => {
        if (result.status === 200) {
          setLoading(false);
        }
      });
      staleMasjids.length === 0 &&
        dispatch(getStaleMasjids("updatedAt", "asc"));
    }
  }, []);

  return (
    <div className="FeedCenterContainer">
   
      {
        <>
          <div className="FeedUpperSection">
            <div className="FeedNumberData">
              <DataBox data={masjids} name={"Masjid"} isLoading={Loading} />
              <DataBox data={admins} name={"Admins"} isLoading={Loading} />
            </div>
            {Loading ? (
              <>
                <div className="skeletonCarousel">
                  {[1, 2, 3, 4].map((item) => (
                    <div key={item} className="SkeletonContainer">
                      <Stack spacing={1}>
                        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton
                          variant="rectangular"
                          width={210}
                          height={60}
                        />
                        <Skeleton variant="rounded" width={210} height={60} />
                      </Stack>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                <div className="FeedEventsTable">
                  <EventsCarousel />
                </div>
              </>
            )}
          </div>
          <div className="FeedLowerSection">
            <div className="FeedMasjidTable">
              <StaleMasjidTable />
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default Feed;
