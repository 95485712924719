import { DELETE_MASJID } from "../../ActionType";
import * as api from "../../../APICalls/index";

export const FreezeMasjidStatus =
  (id: string, status: boolean = true) =>
  async () => {
    try {
      let formData = {
        isFreeze: status ? true : false,
      };
      const response = await api.freezeMasjid(id, formData);

      if (response.status === 200) {
        let result = {
          success: true,
          message: `Successfully ${status ? "freezed" : "unfreezed"} masjid`,
          masjids: response.data.data,
        };

        return result;
      }
      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  };
