import { gql } from "@apollo/client";

export const GET_STRIPEACCOUNTS = gql`
  query StripeAccounts {
    stripeAccounts {
      _id
      stripeAccountId
      masjidId
      status
      isApproved
      stripeDetails {
        email
        contact
        accountOwnerName
      }
      masjidName
      adminName
      adminEmail
      lastLedger {
        _id
        masjidId
        approvalId
        message
        status
        updatedByUser
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_ACCOUNTDETAILS = gql`
  query AccountDetails($masjidId: String!) {
    accountDetails(masjidId: $masjidId) {
      _id
      stripeAccountId
      masjidId
      status
      isApproved
      stripeDetails {
        email
        contact
        accountOwnerName
        businessName
      }
      masjidName
      adminName
      adminEmail
      lastLedger {
        _id
        masjidId
        approvalId
        message
        status
        updatedByUser
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const GET_LOGS = gql`
  query ApprovalLedgers($masjidId: String!) {
    approvalLedgers(masjidId: $masjidId) {
      _id
      masjidId
      approvalId
      message
      status
      updatedByUser
      createdAt
    }
  }
`;
