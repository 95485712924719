import { FETCH_ADMIN_MASJIDS } from '../../ActionType';
import * as api from '../../../APICalls/index'
import {ChangeSnackbar} from '../SnackbarActions/SnackbarActions'
import { Dispatch } from 'redux';


export const getAdminMasjids = (id:string) => async(dispatch:Dispatch<any>) => {
    try{
    
      const { data } = await api.fetchAdminMasjids(id);

      if(data.success)
      {
         dispatch({type:"FETCH_ADMIN_MASJIDS" , payload: data.data})
         return data;
       }
     return data;
    }    
    catch(error:any)
   {
      const snackbarFailureDetails = {
         snackbarOpen:true, 
          snackbarType:"error",
          snackbarMessage:error.response.data ?error.response.data.message:"Fetching Masjid Failed",
        }
   
       dispatch(ChangeSnackbar(snackbarFailureDetails)); 
      return error.response.data;

   }
}