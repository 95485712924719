// import { CHANGE_SNACKBAR } from '../../ActionType';
import { Dispatch } from "redux";
type PayloadType = {
  snackbarOpen: boolean;
  snackbarType: string;
  snackbarMessage: string;
};
export type SnackbarAction = {
  type: string;
  payload: PayloadType;
};
export const ChangeSnackbar =
  (snackbarDetails: PayloadType) =>
  async (dispatch: Dispatch<SnackbarAction>) => {
    try {
      dispatch({ type: "CHANGE_SNACKBAR", payload: snackbarDetails });
    } catch (error: any) {
      return error.message;
    }
  };
