import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./Login.css";
import { authLogin } from "../../../Redux/Actions/AuthActions/LoginAction";
import { VerifyingTwoFactorAuth } from "../../../Redux/Actions/AuthActions/VerifyTwoFactorAuth";
import { CircularProgress } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ReCAPTCHA from "react-google-recaptcha";
import { ResettingGoogleAuth } from "../../../Redux/Actions/AuthActions/ResetGoogleAuthentication";
import { ResettingGoogleVerify } from "../../../Redux/Actions/AuthActions/ResetGoogleVerify";
import { useAppThunkDispatch } from "../../../Redux/hooks";
import { ResponseType } from "../../../../Types";
import QRCodeModel from "./QRCodeModel";
import PasswordInput from "../../Shared/PasswordInput/PasswordInput";
import { toast } from "react-hot-toast";
// import { PasswordInput } from "@RiyadTangil/cmr";
import AuthLeft from "../../Shared/AuthLeft/AuthLeft";
import OTPDialog from "../../Shared/OTPDialog/OTPDialog";

interface ResultType {
  success: boolean;
  TwoFAUser: boolean;
  adminId: string;
  message: string;
}
const Login = () => {
  const siteKey = import.meta.env.VITE_RECAPTCHA_KEY || "";
  const email = useRef<HTMLInputElement>(null);
  const password = useRef<HTMLInputElement>(null);
  const token = useRef<HTMLInputElement>(null);
  const otp = useRef<HTMLInputElement>(null);
  const reCAPTCHA = useRef<ReCAPTCHA>(null);
  const dispatch = useAppThunkDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [adminId, setAdminId] = useState("");
  const [OpenModal, setOpenModal] = useState(false);
  const [OpenModalRefresh, setOpenModalRefresh] = useState(false);
  const [QRCodeModal, setQRCodeModal] = useState(false);
  const [CaptchaValue, setCaptchaValue] = useState(false);
  const [Captcha, setCaptcha] = useState("");
  const [AllowCancel, setAllowCancel] = useState(false);
  const [QRCode, setQRCode] = useState("");
  const [showPas, setShowPas] = useState(false);
  function onChange(tokenArg: string | null) {
    if (tokenArg && !CaptchaValue) {
      setCaptchaValue(true);
    }
    if (tokenArg) setCaptcha(tokenArg);
  }

  const handleModalUpdate = () => {
    setOpenModal(false);
    setIsFetching(false);
  };

  const handleChange = () => {
    if (
      token.current?.value?.length !== undefined &&
      token.current.value.length > 0
    ) {
      setAllowCancel(true);
    } else if (token.current?.value?.length === 0) {
      setAllowCancel(false);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    const currentEmail = email.current?.value;
    const currentPass = password.current?.value;
    e.preventDefault();
    if (!CaptchaValue) toast.error("Please Verify the Captcha .");
    else if (!currentEmail && !currentPass)
      toast.error("Please Provide the Credentials to login");
    else {
      setIsFetching(true);
      const res = dispatch(
        authLogin(
          { email: currentEmail ?? "", password: currentPass ?? "" },
          Captcha
        )
      );

      res.then((result: ResultType) => {
        if (!result.success) toast.error(`Failed To LogIn: ${result.message}`);
        else if (result.TwoFAUser) {
          setAdminId(result.adminId);
          setOpenModal(true);
        } else {
          setAdminId(result.adminId);
          toast.success("Logged In Successfully");
        }
        setIsFetching(false);
      });
      setIsFetching(false);
    }
  };

  const handleAdmin = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsFetching(true);
    e.preventDefault();
    let formData = {
      userId: adminId,
      token: token.current?.value,
      password: password.current?.value,
    };

    const res = dispatch(VerifyingTwoFactorAuth(formData));
    res.then((result: ResponseType) => {
      if (result.success) {
        toast.success("Logged In Successfully .");
        setOpenModal(false);
      } else {
        toast.error("Failed To LogIn : Invalid token ");
      }
      setIsFetching(false);
    });
  };

  const handleOtpApiRequest = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsVerifying(true);
    e.preventDefault();
    let formData = {
      email: email?.current?.value,
      otp: Number(otp?.current?.value),
      password: password?.current?.value,
    };

    const res = dispatch(ResettingGoogleVerify(formData));
    res.then((result: any) => {
      if (result.success) {
        setQRCode(result.QRCode);
        toast.success(result.message);
        setOpenModalRefresh(false);
        setQRCodeModal(true);
      } else {
        toast.error(`Failed To Reset : ${result?.message?.message}`);
      }
      setIsVerifying(false);
    });
  };

  const handleResetGoogleAuth = () => {
    setIsResetting(true);
    const res = dispatch(ResettingGoogleAuth(email.current?.value));
    res.then((result: any) => {
      if (result.success) {
        toast.success(result.message);
        setOpenModal(false);
        setOpenModalRefresh(true);
      } else {
        toast.error(`Failed To Reset : ${result.message}`);
      }
      setIsResetting(false);
    });
  };

  return (
    <div className="login">
      <div className="loginWrapper">
        <AuthLeft />
        <div className="loginRight">
          <div className="QrCode"></div>
          <div className="loginBox">
            {/* Otp Verifier  Model */}
            <Dialog open={OpenModal}>
              <DialogTitle>
                {" "}
                Enter The OTP From Your Google Auth App
              </DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  label="OTP"
                  sx={{ marginLeft: 10, marginTop: 2 }}
                  type="number"
                  onChange={handleChange}
                  inputRef={token}
                  variant="outlined"
                />
              </DialogContent>
              <DialogActions style={{ justifyContent: "space-around" }}>
                <Button
                  variant="contained"
                  onClick={handleModalUpdate}
                  disabled={isFetching}
                  style={{ color: "grey" }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                    handleAdmin(e)
                  }
                >
                  {" "}
                  {isFetching ? (
                    <CircularProgress size="20px" />
                  ) : (
                    "Verify"
                  )}{" "}
                </Button>
              </DialogActions>
              <DialogActions style={{ justifyContent: "space-around" }}>
                <Button
                  variant="outlined"
                  color="warning"
                  disabled={AllowCancel || isResetting}
                  onClick={handleResetGoogleAuth}
                >
                  {" "}
                  {isResetting ? (
                    <CircularProgress size="20px" />
                  ) : (
                    "Reset 2FA"
                  )}{" "}
                </Button>
              </DialogActions>
            </Dialog>
            {/* Otp to mail address Model */}
            <OTPDialog
              open={OpenModalRefresh}
              email={email}
              setOpen={setOpenModalRefresh}
              handleOtpApiRequest={handleOtpApiRequest}
              isVerifying={isVerifying}
            />
            <form onSubmit={handleSubmit} className="loginBox">
              <input
                placeholder="Email"
                type="email"
                ref={email}
                required
                className="loginInput"
              />
              <PasswordInput
                reference={password}
                pHolder={"Password"}
                showPas={showPas}
                setShowPas={setShowPas}
              />
              <div className="ReCaptchaDivDesign">
                <ReCAPTCHA
                  ref={reCAPTCHA}
                  sitekey={siteKey}
                  onChange={onChange}
                />
              </div>
              <button
                className="loginButton"
                type="submit"
                disabled={isFetching}
              >
                {isFetching ? <CircularProgress size="20px" /> : "Log In"}
              </button>
              <span className="loginForgot">
                <Link to="/forgotpassword">Forgot Password?</Link>
              </span>
            </form>
          </div>
        </div>
        {/* Initial OTP Scanner app setter Model */}
        <QRCodeModel
          QRCodeModal={QRCodeModal}
          QRCode={QRCode}
          setQRCodeModal={setQRCodeModal}
        />
      </div>
    </div>
  );
};
export default Login;
