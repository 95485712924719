import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { getFreezedMasjids } from "../../../Redux/Actions/MasjidActions/FetchingFreezedMasjid";
import { ChangeSnackbar } from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import "./FreezeMasjidTable.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress } from "@material-ui/core";
import { useAppThunkDispatch } from "../../../Redux/hooks";
import { Masjid } from "../../../../Types";
import { FreezeMasjidStatus } from "../../../Redux/Actions/MasjidActions/FreezingMasjid";


const today = moment();

const FreezeMasjidTable = () => {
  // not require now may be required later
  // const currentAdmin = useSelector((state) => state.currentAdmin);
  const dispatch = useAppThunkDispatch();
  const [FreezedMasjids, setFreezedMasjids] = useState<Masjid[]>([]);
  const [isUnFreezing, setisUnFreezing] = useState(false);
  const [FreezeModalOpen, setFreezeModalOpen] = useState(false);
  const [UnfreezingMasjid, setUnfreezingMasjid] = useState<Masjid | undefined>(
    undefined
  );

  const handleModelOpen = (adminDetails: any) => {
    setFreezeModalOpen(true);
    setUnfreezingMasjid(adminDetails.row);
  };
  const userColumns: GridColDef[] = [
    {
      field: "masjidName",
      headerName: "Masjid Name",
      //autoComplete: "off",
      width: 280,
    },
    {
      field: "updatedAt",
      headerName: "Last Updated On",
      //autoComplete: "off",
      width: 220,
      renderCell: (params: any) => {
        return (
          <div className="dataUpdatedAt">
            {moment(params.row.updatedAt).format("MM/DD/YYYY hh:mm a")}
          </div>
        );
      },
    },
    {
      field: "Days Before",
      headerName: "Days since last update",
      //autoComplete: "off",
      width: 100,
      renderCell: (params: any) => {
        return (
          <div>
            {moment(today.format()).diff(moment(params.row.updatedAt), "days")}
          </div>
        );
      },
    },
    {
      field: "lastEditor",
      headerName: "Last Updated By",
      width: 200,
      editable: false,
      renderCell: (params: any) => {
        return <div>{params.row.lastEditor.name}</div>;
      },
    },
    {
      field: "contact",
      headerName: "Contact",
      //autoComplete: "off",
      width: 170,
    },
    {
      field: "address",
      headerName: "Address",
      width: 240,
      //autoComplete: "off",
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params: any) => {
        return (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleModelOpen(params);
            }}
            style={{
              border: "1px solid green",
              color: "green",
              height: "20px",
              fontSize: "10px",
              cursor: "Pointer",
            }}
          >
            UnFreeze
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    const response = dispatch(getFreezedMasjids());
    response.then(function (result: any) {
      if (result.success) {
        setFreezedMasjids(result.masjids);
      } else {
        const snackbarFailureDetails = {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: result.message
            ? "Failed to Freeze Masjid Details : " + result.message
            : "Failed to Freeze Masjid Details  : Internet or Server Issue ",
        };
        dispatch(ChangeSnackbar(snackbarFailureDetails));
      }
    });
  }, []);

  const handleUnFreezeModal = () => {
    setFreezeModalOpen(false);
  };

  const handleUnFreezeAdmin = () => {
    setisUnFreezing(true);
    if (UnfreezingMasjid?._id) {
      const response = dispatch(FreezeMasjidStatus(UnfreezingMasjid?._id, false));
      response.then(function (result: any) {
        if (!result.success) {
          const snackbarFailureDetails = {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: result.message
              ? "Failed to  UnFreezed Masjid Details : " + result.message
              : "Failed to UnFreezed Masjid Details : Internet or Server Issue ",
          };
          dispatch(ChangeSnackbar(snackbarFailureDetails));
          setisUnFreezing(false);
        } else {
          const snackbarFailureDetails = {
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: result?.message || "Successfully Unfreezed masjid",
          };
          dispatch(ChangeSnackbar(snackbarFailureDetails));
          setFreezeModalOpen(false);
          setisUnFreezing(false);
          setFreezedMasjids(
            FreezedMasjids.filter(
              (masjid: Masjid) => masjid?._id !== UnfreezingMasjid?._id
            )
          );
        }
      });
    }
  };

  return (
    <div className="FreezedMasjidTableContainer">
      <Dialog
        open={FreezeModalOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure you want to Unfreeze the Masjid ?`}
        </DialogTitle>
        <DialogActions style={{ justifyContent: "space-around" }}>
          <Button
            onClick={handleUnFreezeModal}
            variant="outlined"
            disabled={isUnFreezing}
          >
            No
          </Button>
          <Button onClick={handleUnFreezeAdmin} variant="outlined">
            {" "}
            {isUnFreezing ? (
              <CircularProgress
                //  color="black"
                size="15px"
              />
            ) : (
              <span> Yes </span>
            )}{" "}
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ height: 700, width: "100%", marginTop: "1.2rem",backgroundColor:"white",borderRadius:"20px" }}>
        <DataGrid
          rows={FreezedMasjids?.length > 0 ? FreezedMasjids : []}
          columns={userColumns}
          pageSize={10}
          getRowId={(row: Masjid) => row._id}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </div>
    </div>
  );
};

export default FreezeMasjidTable;
