import React from "react";
import "./DataBox.css";
import { CircularProgress } from "@mui/material";
import { Masjid } from "../../../../Types";

interface DataBoxProps {
  data: Masjid[];
  name: string;
  isLoading: boolean;
}

const DataBox: React.FC<DataBoxProps> = ({ data, name, isLoading }) => {
  return (
    <>
      <div className="DataBoxContainer">
        <div className="NumberDetails page-top-title">
          {isLoading ? <CircularProgress /> : data.length}
        </div>
        <div className="TitleDetails">{isLoading ? <></> : name}</div>
      </div>
    </>
  );
};

export default DataBox;
