export const roleRenamer = (role: string) => {
  if (!role) return role;
  else {
    const displayRole =
      role === "subadmin"
        ? "Masjid admin"
        : role === "admin"
        ? "Data Entry"
        : role;
    return displayRole.charAt(0).toUpperCase() + displayRole.slice(1);
  }
};
