import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { CellDataType } from "../../../../Types";
import { Typography } from "@mui/material";
import moment from "moment";
import { roleRenamer } from "../../../helpers/RoleRenamer";

export const MasjidColumns: any = [
  {
    field: "masjidName",
    headerName: "Masjid Name",
    autoComplete: "off",
    width: 280,
    renderCell: (params: CellDataType) => {
      return (
        <div className="cellWithImg">
          <LazyLoadImage
            effect="blur"
            className="cellImg"
            src={params.row.masjidProfilePhoto}
            alt="avatar"
          />
          <Link
            to={"/masjid/" + params.id}
            target="_blank"
            rel="noopener noreferrer"
          >
            {params.row.masjidName}
          </Link>
        </div>
      );
    },
  },
  {
    field: "contact",
    headerName: "Contact",
    autoComplete: "off",
    width: 180,
  },
  {
    field: "lastEditor",
    headerName: "Last Updated By",
    width: 180,
    editable: "never",
    renderCell: (params: CellDataType) => {
      return <div>{params.row?.lastEditor?.name}</div>;
    },
    valueGetter: (params: CellDataType) => params.row.lastEditor?.name,
  },
  {
    field: "address",
    headerName: "Address",
    width: 400,
    autoComplete: "off",
  },
  {
    field: "Managed By",
    headerName: "Managed By",
    width: 150,
    valueGetter: (params: CellDataType) => params.row.lastEditor?.role,
    renderCell: (params: CellDataType) => {
      let color;

      // Check the role and set the color accordingly
      if (
        params.row?.lastEditor?.role === "superadmin" ||
        params.row?.lastEditor?.role === "admin"
      ) {
        color = "blue"; // Set the color for superadmin/admin
      } else if (
        params.row?.lastEditor?.role === "subadmin" ||
        params.row?.lastEditor?.role === "musaliadmin"
      ) {
        color = "green"; // Set the color for subadmin/musalliadmin
      } else {
        color = "black"; // Default color if the role is not recognized
      }
      const role = params.row?.lastEditor?.role;
      const displayRole = roleRenamer(role);
      return (
        <Typography style={{ color }} variant="body1">
          {displayRole}
        </Typography>
      );
    },
  },
  {
    field: "Admin Name",
    headerName: "Admin Name",
    width: 150,
    valueGetter: (params: CellDataType) => params.row.lastEditor?.name,
  },
  {
    field: "Last Updated BY",
    headerName: "Last Updated On",
    width: 150,
    valueGetter: (params: CellDataType) =>
      moment(params.row.updatedAt).format("MM/DD/YYYY hh:mm a"),
  },
];
