import React, { useEffect } from "react";
import SideBar from "../../../Components/FeedComponents/SideBar/SideBar";
import TopBar from "../../../Components/FeedComponents/TopBar/TopBar";
import AllAdminsTable from "../../../Components/PageComponents/AllAdminsTable/AllAdminsTable";
import { getMasjids } from "../../../Redux/Actions/MasjidActions/FetchingMasjids";
import { getAdminMasjids } from "../../../Redux/Actions/MasjidActions/FetchingMasjidByAdmin";
import { getStaleMasjids } from "../../../Redux/Actions/MasjidActions/FetchingStaleMasjids";
import { useAppSelector, useAppThunkDispatch } from "../../../Redux/hooks";
import { AdminInterFace } from "../../../Components/FeedComponents/Feed/Feed";
import PageContainer from "../../Shared/PageContainer/PageContainer";

const Admins = () => {
  const dispatch = useAppThunkDispatch();
  const sidebarState = useAppSelector((state) => state.sidebarState);

  const staleMasjids = useAppSelector((state) => state.staleMasjids);
  const AdminMasjids = useAppSelector((state) => state.AdminMasjids);
  const adminString = localStorage.getItem("admin");
  const admin: AdminInterFace | null = adminString
    ? JSON.parse(adminString)
    : null;
  const masjids = useAppSelector((state) => state.masjids);

  useEffect(() => {
    if (admin?.role === "superadmin" || admin?.role === "admin") {
      masjids.length === 0 && dispatch(getMasjids("createdAt", "asc"));
      staleMasjids.length === 0 &&
        dispatch(getStaleMasjids("updatedAt", "asc"));
    } else {
      AdminMasjids.length === 0 && dispatch(getAdminMasjids(admin?._id ?? ""));
    }
  }, []);

  return (
    <>
      <PageContainer pl={"10px"} pt={"20px"} title={"Admins"}>
        <div className="RightFeedContainer">
          <AllAdminsTable />
        </div>
      </PageContainer>
    </>
  );
};

export default Admins;
