import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";

type PropsType={
    QRCodeModal:boolean;
    QRCode:string;
    setQRCodeModal: (value: boolean) => void;


}
const QRCodeModel = ({QRCodeModal,QRCode,setQRCodeModal}:PropsType) => {
  return (
    <>
      <Dialog open={QRCodeModal}>
        <DialogTitle>
          Scan This QR Code With Google Authenticator App To Get Back TOTP
        </DialogTitle>
        <DialogContent>
          <Box
            component="img"
            sx={{ height: 260, width: 270, marginLeft: 10 }}
            alt="Qr Code"
            src={QRCode}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={(e) => {
              setQRCodeModal(false);
            }}
            style={{ color: "grey" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QRCodeModel;
