// src/apolloClient.ts
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { adminFromLocalStg } from "./helpers/AdminFromLocalStorage/AdminFromLocalStorage";

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_URL || "",
});

const authLink = setContext((_, { headers }) => {
  const authTokens = adminFromLocalStg("authTokens");
  const token = authTokens?.accessToken || "";
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
