import React, { useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";

interface OTPDialogProps {
  open: boolean;
  email: any;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleOtpApiRequest: (otpVal: string) => void;
  isVerifying: boolean;
}

const OTPDialog: React.FC<OTPDialogProps> = ({
  open,
  email,
  setOpen,
  handleOtpApiRequest,
  isVerifying,
}) => {
  const [otp, setOtp] = useState("");

  return (
    <Dialog open={open}>
      <DialogTitle>Enter The OTP Sent To : {email}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          placeholder="OTP"
          margin="dense"
          value={otp}
          label="OTP"
          fullWidth
          sx={{ marginTop: 2 }}
          type="number"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setOtp(e.target.value)
          }
          inputMode="numeric"
          variant="outlined"
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-around" }}>
        <Button
          variant="contained"
          onClick={() => setOpen(false)}
          style={{ color: "grey" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => handleOtpApiRequest(otp)}
          disabled={isVerifying}
        >
          {isVerifying ? <CircularProgress size="20px" /> : "Verify OTP"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OTPDialog;
