import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CRM from "./CRM";

const CRMModal = ({ consumerMasjidId }) => {
  const [FreezeModalOpen, setFreezeModalOpen] = useState(false);

  const handleClose = () => {
    setFreezeModalOpen(false);
  };

  return (
    <>
      <Dialog
        open={FreezeModalOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          CRM
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CRM consumerMasjidId={consumerMasjidId} />
        </DialogContent>
      </Dialog>

      <Button
        onClick={() => setFreezeModalOpen(true)}
        style={{
          border: "1px solid red",
          color: "red",
          height: "20px",
          fontSize: "10px",
          marginLeft: "10px",
          marginBottom: "10px",
          cursor: "pointer",
        }}
      >
        CRM
      </Button>
    </>
  );
};

export default CRMModal;
