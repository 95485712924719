import { FETCH_MASJIDS } from "../../ActionType";
import { Action, Masjid } from "../../../../Types";

const FetchMasjidReducer = (
  masjids: Masjid[] = [],
  action: Action
): Masjid[] => {
  switch (action.type) {
    case FETCH_MASJIDS:
      return action.payload;
    default:
      return masjids;
  }
};

export default FetchMasjidReducer;
