import * as api from "../../../APICalls/index";
// import { Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { ChangeSnackbar } from "../SnackbarActions/SnackbarActions";
import { Dispatch } from "redux";

type formDataType = {
  password: string;
  token: any;
};
export const resetPasswordInitial =(formData:formDataType) => async (dispatch: Dispatch) => {
    try {
      const response = await api.setPasswordNewUser(formData);

      if (response.status === 200) {
        //cookies 5
        let token = {
          accessToken: response.data.data.accessToken,
          refreshToken: response.data.data.refreshToken,
        };

        localStorage.setItem("authTokens", JSON.stringify(token));

        let result = {
          data: response.data.data,
          success: true,
        };
        return result;
      }
      return response.data.data;
    } catch (error: any) {
      if (error.response.data) {
        let result = {
          success: false,
          TwoFAUser: false,
          error: `Failed to Login`,
          message: error.response.data.data.error,
        };
        return result;
      }
      return error.response;
    }
  };
