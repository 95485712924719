import { TextField } from "@material-ui/core";
import React from "react";
type propsType = {
  isError?: boolean;
  inputRf: any;
  placeholderTx: string;
  height?: string;
  defVal?: string;
};
const SocialInput = ({
  isError = false,
  defVal = "",
  inputRf,
  placeholderTx,
  height = "50%",
}: propsType) => {
  return (
    <>
      <TextField
        id="outlined-required"
        variant="outlined"
        defaultValue={defVal}
        error={isError}
        helperText={isError ? "Manditory field" : ""}
        fullWidth
        inputRef={inputRf}
        type="text"
        autoComplete="Off"
        style={{ marginTop: 15, height: height }}
        placeholder={placeholderTx}
      />
    </>
  );
};

export default SocialInput;
