import { AUTH_LOGIN } from "../../ActionType";
import { UserActionType } from "../../../../Types";
import { adminFromLocalStg } from "../../../helpers/AdminFromLocalStorage/AdminFromLocalStorage";

// eslint-disable-next-line import/no-anonymous-default-export
const LoginReducer = (admin = [], action: UserActionType) => {
  switch (action.type) {
    case AUTH_LOGIN:
      localStorage.setItem("admin", JSON.stringify(action.payload));
      return action.payload;
    case "MASJID_ID_SETTER":
      const localAdmin = adminFromLocalStg();
      const userWithMasjidId = { ...localAdmin, ...action.payload };
      localStorage.setItem("admin", JSON.stringify(userWithMasjidId));
      return userWithMasjidId;
    case "MASJID_ID_REMOVER":
      const userFromLocalStg = adminFromLocalStg();
      const userWithOutMasjidId = { ...userFromLocalStg, masjids: [] };
      localStorage.setItem("admin", JSON.stringify(userWithOutMasjidId));
      return userWithOutMasjidId;
    default:
      return admin;
  }
};

export default LoginReducer;
