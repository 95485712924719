import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { getFreezedAdmins } from "../../../Redux/Actions/AdminActions/FetchingFreezedAdmins";
import { ChangeSnackbar } from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import "./FreezeAdminTable.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { FreezeAdmin } from "../../../Redux/Actions/AdminActions/FreezingAdmin";
import { useAppThunkDispatch } from "../../../Redux/hooks";
import { CellDataType, User } from "../../../../Types";
import { Typography } from "@mui/material";
import { roleRenamer } from "../../../helpers/RoleRenamer";

const FreezeAdminTable = () => {
  const dispatch = useAppThunkDispatch();
  let navigate = useNavigate();
  const [FreezedAdmins, setFreezedAdmins] = useState([]);
  const [isUnFreezing, setisUnFreezing] = useState(false);
  const [FreezeModalOpen, setFreezeModalOpen] = useState(false);
  const [UnfreezingAdmin, setUnfreezingAdmin] = useState<User>();

  const handleModelOpen = (adminDetails: any) => {
    setFreezeModalOpen(true);
    setUnfreezingAdmin(adminDetails.row);
  };

  const adminColumns = [
    {
      field: "name",
      title: "Admin Name",
      headerName: "Admin Name",
      autoComplete: "off",
      width: 380,
    },
    {
      field: "updatedAt",
      title: "Last Updated On",
      headerName: "Last Updated On",
      autoComplete: "off",
      width: 220,
      renderCell: (params: any) => {
        return (
          <div className="dataUpdatedAt">
            {moment(params.row.updatedAt).format("MM/DD/YYYY hh:mm a")}
          </div>
        );
      },
    },
    {
      field: "email",
      title: "Email",
      headerName: "Email",
      width: 300,
      autoComplete: "off",
    },

    {
      field: "role",
      headerName: "Role",
      width: 150,
      renderCell: (params: CellDataType) => {
        const role = params.row.role;
        const displayRole = roleRenamer(role);
        return <Typography variant="body1">{displayRole}</Typography>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params: any) => {
        return (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleModelOpen(params);
            }}
            style={{
              border: "1px solid green",
              color: "green",
              height: "20px",
              fontSize: "10px",
              cursor: "Pointer",
            }}
          >
            UnFreeze
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    const response = dispatch(getFreezedAdmins());
    response.then(function (result) {
      if (result.success) {
        setFreezedAdmins(result.masjids);
      } else {
        const snackbarFailureDetails = {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: result.message
            ? "Failed to Fetch Freezed Admins Details : " + result.message
            : "Failed to Fetch Freezed Admins Details : Internet or Server Issue ",
        };
        dispatch(ChangeSnackbar(snackbarFailureDetails));
      }
    });
  }, []);

  const handleUnFreezeModal = () => {
    setFreezeModalOpen(false);
  };

  const handleFetchAdmins = () => {
    const response = dispatch(getFreezedAdmins());
    response.then(function (result) {
      if (result.success) {
        setFreezedAdmins(result.masjids);
      } else {
        const snackbarFailureDetails = {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: result.message
            ? "Failed to Fetch Freezed Admins Details : " + result.message
            : "Failed to Fetch Freezed Admins Details : Internet or Server Issue ",
        };
        dispatch(ChangeSnackbar(snackbarFailureDetails));
      }
    });
  };

  const handleUnFreezeAdmin = () => {
    setisUnFreezing(true);
    let formData = {
      userId: UnfreezingAdmin?._id,
      isFreeze: false,
    };
    const response = dispatch(FreezeAdmin(formData));

    response.then(function (result) {
      if (result.message === "User updated successfully") {
        const snackbarSuccessDetails = {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "UnFreezed The Admin Successfully",
        };
        dispatch(ChangeSnackbar(snackbarSuccessDetails));
        handleFetchAdmins();
        setisUnFreezing(false);
        setFreezeModalOpen(false);
        navigate("/freezeAdmins");
      } else {
        const snackbarFailureDetails = {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Couldn't Unfreeze the Admin  : " + result.message,
        };
        setisUnFreezing(false);
        setFreezeModalOpen(false);
        dispatch(ChangeSnackbar(snackbarFailureDetails));
      }
    });
  };

  return (
    <div className="FreezedMasjidTableContainer">
      <Dialog
        open={FreezeModalOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure you want to Unfreeze the Admin ?`}
        </DialogTitle>
        <DialogActions style={{ justifyContent: "space-around" }}>
          <Button
            onClick={handleUnFreezeModal}
            variant="contained"
            color="inherit"
            disabled={isUnFreezing}
          >
            No
          </Button>
          <Button
            onClick={handleUnFreezeAdmin}
            variant="contained"
            color="primary"
          >
            {" "}
            {isUnFreezing ? (
              <CircularProgress
                // color="black"
                size="15px"
              />
            ) : (
              <span> Yes </span>
            )}{" "}
          </Button>
        </DialogActions>
      </Dialog>
      <div className="freezed-admin-table">
        <DataGrid
          rows={FreezedAdmins?.length > 0 ? FreezedAdmins : []}
          columns={adminColumns}
          pageSize={10}
          getRowId={(row: User) => row?._id}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </div>
    </div>
  );
};

export default FreezeAdminTable;
