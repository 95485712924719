import React from "react";
import "./Dashboard.css";
import Feed from "../../Components/FeedComponents/Feed/Feed";
import PageContainer from "../Shared/PageContainer/PageContainer";

const Dashboard: React.FC = () => {

  return (
    <>
      <PageContainer title={"Dashboard"}>
        <Feed />
      </PageContainer>
    </>
  );
};

export default Dashboard;
