import React from "react";
import "./EventCarousel.css";
import EventsCard from "./EventsCard";
import { useAppSelector } from "../../../Redux/hooks";
import { Masjid } from "../../../../Types";

const EventsCarousel: React.FC = () => {
  const masjids = useAppSelector((state) => state.masjids);
  const LatestEvents = useAppSelector((state) => state.LatestEvents);

  return (
    <div className="EventsSectionTop">
      <span className="EventSectionTitle">Latest Updated Events</span>
      <div className="EventRoll">
        {LatestEvents?.length > 0 ? (
          LatestEvents.slice(0, 4).map((item:any) => {
            const masjid = masjids.filter(
              (masjid: Masjid) => masjid._id === item.masjid
            );

            return (
              <>
                <EventsCard item={item} masjid={masjid} />
              </>
            );
          })
        ) : (
          <>
            <div className="EventsContainer">
              <h5 style={{ marginLeft: "10px" }}>
                Sorry!! No Events Found For you
              </h5>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EventsCarousel;
