import Slider from "react-slick";
import React, { useEffect, useState } from "react";
// import { useAppSelector, useAppThunkDispatch } from "../../../redux/hooks";
// import { fetchMasjidById } from "../../../redux/actions/MasjidActions/fetchMasjidById";
import toast from "react-hot-toast";
import proflePlaceholer from "../../Photos/Newuiphotos/homeicon/profile_placeholder.png";
import program from "../../Photos/Newuiphotos/menuIcons/program.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CardActionArea,
  Box,
} from "@mui/material";
import { Link, useNavigate, useNavigation, useParams } from "react-router-dom";
import "./Menu.css"; // CSS file for custom styling

// Icons Import
import AboutIcon from "../../Photos/Newuiphotos/menuIcons/about.webp";
import SalahIcon from "../../Photos/Newuiphotos/menuIcons/salah.webp";
import OtherSalahIcon from "../../Photos/Newuiphotos/menuIcons/otherSalah.webp";
import EventIcon from "../../Photos/Newuiphotos/menuIcons/event.webp";

import AnnouncementIcon from "../../Photos/Newuiphotos/menuIcons/announcement.svg";
// import AdminProfile from "../AdminProfile/AdminProfile";
// import TvConnection from "../TvConnectionPage/TvConnection";
// import TermAndConditions from "../Shared/TermAndConditions";
import {
  MyProvider,
  MasjidProfile,
  MobileViewCalender,
  OtherSalahComponent,
  EventsViewCalender,
  AdminProfile,
  Toaster,
  // BottomNavigation,
  EventDetails,
  Announcement,
  masjidIdRemover,
  masjidIdSetter,
  masjidStateUnmount,
  Programs,
} from "@msa-software-llc/connectmazjid-vanilaportal";
import "@msa-software-llc/connectmazjid-vanilaportal/dist/style.css";

import MasjidTop from "../ProfileComponents/MasjidTop/MasjidTop";
import { useAppThunkDispatch } from "../../Redux/hooks";
import { FetchMasjidById } from "../../Redux/Actions/MasjidActions/FetchMasjidById";
import SalahTiming from "../../Pages/DetailPages/SalahTiming/SalahTiming";

const Menu = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  // const consumerMasjidId = localAdmin.masjids[0];
  // let AdminMasjidState = useAppSelector((state) => state.AdminMasjid);

  const consumerMasjidId = sessionStorage.getItem("masjidId");
  const [masjid, setMasjid] = useState<any>(null);
  const dispatch = useAppThunkDispatch();
  const { tab } = useParams();

  // const [followers, setFollowers] = useState<number>(50);
  // const [myMasjidCount, setMyMasjidCount] = useState<number>(20);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [showTVConnection, setShowTVConnection] = useState(false);
  const [tmConOpener, setTmConOpener] = useState(false);
  const [isAdminMasjidStateLoaded, setIsAdminMasjidStateLoaded] =
    useState(false);

  const masjidAPIRequest = () => {
    const response = dispatch(FetchMasjidById(consumerMasjidId));
    response.then(function (result) {
      console.log(result);
      if (result.data?.masjidName) {
        setMasjid(result.data);
      } else {
        toast.error("Unable to fetch Masjid data");
      }
    });
  };

  useEffect(() => {
    if (consumerMasjidId) {
      masjidAPIRequest();
    }
  }, [consumerMasjidId]);

  useEffect(() => {
    if (tab) setActiveTab(+tab);
  }, [tab]);

  // useEffect(() => {
  //   if (AdminMasjidState && AdminMasjidState.lastEditor) {
  //     setIsAdminMasjidStateLoaded(true);
  //   }
  // }, [AdminMasjidState]);

  // Menu items
  const menuItems = [
    {},
    {
      label: "About Masjid",
      link: "/feed/1",
      icon: AboutIcon,
      content: <MasjidProfile consumerMasjidId={consumerMasjidId} />,
    },

    {
      label: "Salah Timings",
      link: "/feed/2",
      icon: SalahIcon,
      content: (
        <SalahTiming
          currentMasjid={masjid}
          consumerMasjidId={consumerMasjidId}
        />
      ),
    },
    {},
    {
      label: "Events",
      link: "/feed/4",
      icon: EventIcon,

      content: (
        <EventsViewCalender
          consumerMasjidId={consumerMasjidId}
          isMainAdmin={true}
        />
      ),
    },
    {
      label: "Announcements",
      link: "/feed/5",
      icon: AnnouncementIcon,
      content: <Announcement consumerMasjidId={consumerMasjidId} />,
    },
    {},
    {},
    {},
    {
      label: "Programs",
      link: "/feed/9",
      icon: program,
      content: <Programs consumerMasjidId={consumerMasjidId} />,
    },
  ];
  useEffect(() => {
    const styleElement = document.createElement("style");

    const loadDynamicStyles = async () => {
      const { default: vanillaPortalStyle } = await import(
        "@msa-software-llc/connectmazjid-vanilaportal/dist/style.css"
      );
      // Append the stylesheet to the document head
      styleElement.textContent = vanillaPortalStyle;
      document.head.appendChild(styleElement);
    };

    loadDynamicStyles();

    return () => {
      const indexOfMyCss = Array.from(document.head.children).indexOf(
        styleElement
      );
    };
  }, []);
  const navigation = useNavigate();

  return (
    <MyProvider navigation={navigation}>
      <Toaster />
      <MasjidTop consumerMasjidId={consumerMasjidId} />
      <div className="main-menu">
        {activeTab === 0 ? (
          <div className="home_main_container">
            <div className="home_desktop">
              <div className="masjid_header">
                <img
                  src={masjid?.masjidProfilePhoto || proflePlaceholer}
                  alt="Masjid"
                  className="header-image"
                />
                <span className="header-name">{masjid?.masjidName}</span>
                {/* <span className="header-followers">{followers} Follower</span>
              <span className="header-myMasjid">{myMasjidCount} MyMasjid</span> */}
              </div>
            </div>
            <div className="slider-container">
              <div className="slider-container-1">
                <Slider {...settings}>
                  {masjid?.masjidPhotos?.length > 0 ? (
                    masjid?.masjidPhotos?.map((photo: any, index: number) => (
                      <div key={index} className="slide">
                        <img
                          src={photo.url}
                          alt={`Masjid Slide ${index}`}
                          className="slide-image"
                        />
                      </div>
                    ))
                  ) : (
                    <div className="slide">
                      <img
                        src={proflePlaceholer}
                        alt={`Masjid Slide}`}
                        className="slide-image"
                      />
                    </div>
                  )}
                </Slider>
              </div>

              <div className="profile-section">
                {/* <div className="followers">
                <div className="count">{followers}</div>
                <div className="label">Followers</div>
              </div> */}
                <div className="profile-image-container">
                  <img
                    src={masjid?.masjidProfilePhoto || proflePlaceholer}
                    alt="Profile"
                    className="profile-image"
                  />
                </div>
                {/* <div className="my-masjid">
                <div className="count">{myMasjidCount}</div>
                <div className="label">My Masjid</div>
              </div> */}
              </div>
              <div className="menu-container">
                <b className="header-name-1">{masjid?.masjidName}</b>

                <Grid container spacing={0} style={{ padding: 0 }}>
                  {menuItems.map((item, index) => {
                    if (
                      index === 0 ||
                      index === 3 ||
                      index === 6 ||
                      index === 7 ||
                      index === 8
                    ) {
                      return null;
                    }
                    return (
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={3}
                        key={index}
                        style={{ padding: "5px" }}
                      >
                        <Card
                          component={Link}
                          to={item.link}
                          onClick={() => setActiveTab(index)}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height: { xs: "15vh", sm: "16vh", md: "20vh" },
                            borderRadius: "10px",
                            textDecoration: "none",
                          }}
                        >
                          <CardActionArea>
                            <CardContent
                              sx={{ textAlign: "center", padding: 0 }}
                            >
                              <Box
                                component="img"
                                src={item.icon}
                                alt={item.label}
                                sx={{
                                  width: { xs: 25, sm: 30, md: 35 },
                                  height: { xs: 25, sm: 30, md: 35 },
                                }}
                              />
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: {
                                    xs: "0.8rem",
                                    sm: "0.8rem",
                                    md: "0.9rem",
                                  },
                                  lineHeight: "1rem",
                                  color: "#1D785A",
                                  fontWeight: "bold",
                                  marginTop: "10px",
                                  textDecoration: "none",
                                }}
                              >
                                {item.label}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </div>
          </div>
        ) : (
          <div className="content-container">
            {menuItems[activeTab].content}
          </div>
        )}
        {/* <TermAndConditions
          tmConOpener={tmConOpener}
          setTmConOpener={setTmConOpener}
        /> */}
        <footer className="terms-footer">
          <Typography
            variant="subtitle2"
            style={{
              textAlign: "center",
              color: "#33443B",
              textDecoration: "underline",
            }}
            onClick={() => setTmConOpener(true)}
          >
            Terms and Conditions
          </Typography>
        </footer>
      </div>
    </MyProvider>
  );
};

export default Menu;
