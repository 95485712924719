import React, { useEffect, useRef, useState } from "react";
import "./ResetPasswordInitial.css";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { resetPasswordInitial } from "../../../Redux/Actions/AuthActions/ResetPasswordInitial";
import { useLocation } from "react-router-dom";
import { ActivatingTwoFactorAuth } from "../../../Redux/Actions/AuthActions/ActivateTwoFactorAuth";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { VerifyingTwoFactorAuth } from "../../../Redux/Actions/AuthActions/VerifyTwoFactorAuth";
import { useAppThunkDispatch } from "../../../Redux/hooks";
import AuthActionBtn from "../../Shared/AuthActionBtn/AuthActionBtn";
import PasswordInput from "../../Shared/PasswordInput/PasswordInput";
import AuthLeft from "../../Shared/AuthLeft/AuthLeft";
import { toast } from "react-hot-toast";

const ResetPasswordInitial = () => {
  useEffect(() => {
    if (localStorage.getItem("admin") !== null) localStorage.clear();
  }, []);
  const navigate = useNavigate();
  const password = useRef<HTMLInputElement | null>(null);
  const confirmPassword = useRef<HTMLInputElement | null>(null);
  const LoginCode = useRef<HTMLInputElement | null>(null);
  const dispatch = useAppThunkDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("token");
  const [OpenQRModal, setOpenQRModal] = useState(false);
  const [AllowCancel, setAllowCancel] = useState(false);
  const [QRCode, setQRCode] = useState("");
  const [UserID, setUserId] = useState("");
  const [showPas, setShowPas] = useState(false);
  const [showConPas, setShowConPas] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password.current?.value === confirmPassword.current?.value) {
      const formData = {
        password: password.current?.value ?? "",
        token: id,
      };

      setIsFetching(true);
      const res = dispatch(resetPasswordInitial(formData));

      res.then((result) => {
        if (result.success) {
          toast.success("Password Setup Successful");
          setIsFetching(false);
        } else {
          toast.error(`Failed To Setup the Password : ` + result.message);
          setIsFetching(false);
        }
      });
    } else {
      toast.error("Password and Confirmed Password does not match");
      setIsFetching(false);
    }
    setIsFetching(false);
  };
  const handleQRClose = () => {
    setIsFetching(true);
    let formData = {
      password: password.current?.value,
      token: LoginCode.current?.value,
      userId: UserID,
      isInitial: true,
    };
    const res = dispatch(VerifyingTwoFactorAuth(formData));
    res.then((result) => {
      if (result.success) {
        toast.success("Verified Successfully");
        navigate("/");
        setIsFetching(false);
        setOpenQRModal(false);
      } else {
        setIsFetching(false);
        toast.error("Request Failed :Invalid token");
        setIsFetching(false);
      }
    });
  };

  const handleChange = () => {
    if (LoginCode.current?.value && LoginCode.current.value.length > 5) {
      setAllowCancel(true);
    } else if (LoginCode.current?.value?.length === 0) {
      setAllowCancel(false);
    }
  };

  return (
    <div className="ForgotPassword">
      <div className="loginWrapper">
        <AuthLeft />
        <div className="loginRight">
          <div className="PoppupAuth">
            <Dialog open={OpenQRModal}>
              <DialogTitle>
                Scan this QR CODE with Google Authenticator App
              </DialogTitle>
              <DialogContent>
                <Box
                  component="img"
                  sx={{ height: 260, width: 270, marginLeft: 10 }}
                  alt="Qr Code"
                  src={QRCode}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  label="TOTP"
                  sx={{ marginLeft: 15, marginTop: 2 }}
                  type="number"
                  onChange={handleChange}
                  inputRef={LoginCode}
                  variant="outlined"
                />
              </DialogContent>
              <DialogActions>
                <p>Enter the TOTP from Google Authenticator App to Submit </p>
                {AllowCancel && (
                  <Button onClick={handleQRClose} style={{ color: "grey" }}>
                    {isFetching ? <CircularProgress size="20px" /> : "Verify"}
                  </Button>
                )}
                {AllowCancel && (
                  <Button
                    onClick={() => setOpenQRModal(false)}
                    style={{ color: "grey" }}
                  >
                    Close
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          </div>
          <div className="loginBox">
            <form onSubmit={handleSubmit} className="ForgotPasswordBox">
              <PasswordInput
                reference={password}
                pHolder={"Password"}
                showPas={showPas}
                setShowPas={setShowPas}
                belowTx={false}
              />
              <PasswordInput
                reference={confirmPassword}
                pHolder={"Confirm Password"}
                showPas={showConPas}
                setShowPas={setShowConPas}
              />
              <AuthActionBtn isFetching={isFetching} />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetPasswordInitial;
