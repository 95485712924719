import moment from "moment";
import toast from "react-hot-toast";
import swal from "sweetalert";
import { AdminInterFace } from "../../Components/FeedComponents/Feed/Feed";
import { AuthTokens } from "../../../Types";

// export const UtcDateConverter = (
//   data: string,
//   tZone: string,
//   timing = "00:00"
// ) => {
//   const doesContain = data.includes("T");
//   const changedDate = doesContain ? data.split("T")[0] : data;
//   const inputFormat = "YYYY-MM-DDTHH:mm";
//   const outputFormat = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";

//   // Append the timing to the changedDate
//   const dateTimeString = `${changedDate}T${timing}`;

//   const formattedDate = moment
//     .tz(dateTimeString, inputFormat, tZone)
//     .utc()
//     .format(outputFormat);

//   return formattedDate;
// };
export const UtcDateConverter = (data: string, tZone: string) => {
  const doesContain = data.includes("T");
  const changedDate = doesContain ? data.split("T")[0] : data;
  const inputFormat = "YYYY-MM-DD";
  const outputFormat = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";

  const locationBasedDate = moment
    .tz(changedDate, inputFormat, tZone)
    .startOf("day");

  const formattedDate = locationBasedDate.format(outputFormat);

  // Convert to UTC
  const utcDate = moment.tz(formattedDate, outputFormat, tZone).utc();
  return utcDate.format(outputFormat);
};

export const UTCTimeConverter = (time: string, date: string, tZone: string) => {
  const momentObj = moment.tz(time, "HH:mm", tZone);

  const firstTxLength = date.split("-")[0].length;
  const dateFormate = firstTxLength > 2 ? "YYYY-MM-DD" : "DD-MM-YYYY";
  const formattedDate = moment(date, dateFormate);
  const year = formattedDate.format("YYYY");
  const month = formattedDate.format("MM");
  const day = formattedDate.format("DD");

  const updatedMoments = momentObj.clone().set({
    year: +year,
    month: Number(month) - 1,
    date: +day,
  });
  return updatedMoments.unix();
};
export const dateReverter = (tm: string | undefined, tZone: string) => {
  // const inputFormate = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";
  if (tm) return moment(new Date(tm), tZone).format("YYYY-MM-DD") || "";
  //last time changed by the request of Bibke
  // if (tm) return moment.tz(tm, inputFormate, tZone).format("YYYY-MM-DD") || "";
  else return "";
};
export const LocationBasedToday = (tzone: string | undefined) => {
  if (tzone) return new Date(moment.tz(tzone).format("YYYY-MM-DD HH:mm:ss"));
  return new Date();
};
export const confirmation = async () => {
  const willDelete = await swal({
    title: "Are you sure?",
    text: "Are you sure about  no update?",
    icon: "warning",
    buttons: ["Cancel", "Yes"],
    dangerMode: false,
  });
  return willDelete;
};
export const UTCTimeReverter = (
  tm: number | undefined,
  tZone: string,
  formatType = "HH:mm"
) => {
  if (tm && tZone) return moment.unix(tm).tz(tZone).format(formatType);
  else return "";
};

export const mailChecker = (email: string) => {
  const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;

  const lowercasedEmail = email.toLowerCase();
  if (!emailPattern.test(lowercasedEmail)) {
    toast.error("Please enter a valid email address.");
    return false;
  }
  return true;
};

export const phoneChecker = (phoneNumber: string) => {
  // Allow numbers, spaces, parentheses, and hyphens
  const phonePattern = /^[0-9 ()-]+$/;
  if (!phonePattern.test(phoneNumber)) {
    toast.error("Please enter a valid phone number.");
    return false;
  }
  if (phoneNumber.length < 10 || phoneNumber.length > 15) {
    toast.error("Phone number must be between 10 and 15 characters.");
    return false;
  }
  return true;
};

export const getAccessToken = () => {
  const authTokensString = localStorage.getItem("authTokens");
  const token: AuthTokens | null = authTokensString
    ? JSON.parse(authTokensString)
    : null;
  return token?.accessToken;
};
export const getRefreshToken = () => {
  const authTokensString = localStorage.getItem("authTokens");
  const token: AuthTokens | null = authTokensString
    ? JSON.parse(authTokensString)
    : null;
  return token?.refreshToken;
};
export const statusHandler = (resStatue: number) => {
  if (resStatue >= 200 && resStatue <= 210) {
    return { success: true };
  } else return { success: false };
};
