import React from "react";
import PageContainer from "../../Shared/PageContainer/PageContainer";
import FreezeAdminTable from "../../../Components/FeedComponents/FreezeAdminTable/FreezeAdminTable";
import VerificationCenterTable from "../../../Components/VerificationCenterComponents/Table/VerificationCenterTable";

const VerificationCenter = () => {
  return (
    <>
      <PageContainer pt={"50px"} title="Verification Center">
        <div className="RightFeedContainer">
          <VerificationCenterTable />
        </div>
      </PageContainer>
    </>
  );
};

export default VerificationCenter;
