import { gql } from "@apollo/client";

// export const CREATE_MEMBER = (payload: {
//   personName: string;
//   masjidId: string;
//   email: string;
//   phoneNumber: string;
//   designation: string;
//   img: string;
// }) => {
//   const mutation = `mutation {
//     masjidMember {
//       createMember(
//         input: {
//           personName: "${payload.personName}"
//           masjidId: "${payload.masjidId}"
//           email: "${payload.email}"
//           phoneNumber: "${payload.phoneNumber}"
//           designation: "${payload.designation}"
//           img: "${payload.img}"
//         }
//       ) {
//         personName
//         email
//         phoneNumber
//         designation
//         img
//       }
//     }
//   }`;

export const CREATE_MEMBER = gql`
  mutation CreateMember($input: CreateMemberInput!) {
    createMember(input: $input) {
      personName
      email
      phoneNumber
      designation
      img
    }
  }
`;
export const UPDATE_MEMBER = gql`
  mutation UpdateMember($input: UpdateMemberInput!) {
    updateMember(input: $input) {
      _id
      personName
      email
      phoneNumber
      designation
      img
    }
  }
`;

export const CREATE_MESSAGE = gql`
  mutation CreateMessage($message: MessageInput!) {
    createMessage(message: $message) {
      masjidId
      body
    }
  }
`;

// export const CREATE_MESSAGE = (payload: { masjidId: string; body: string }) => {
//   const mutation = `mutation {
//     message {
//       createMessage(  message: {
//           masjidId: "${payload.masjidId}"
//           body: "${payload.body}"
//         } ) {
//         masjidId
//         body
//       }
//     }
//   }`;

//   return gql`
//     ${mutation}
//   `;
// };
