import { ADD_MASJID } from "../../ActionType";
import * as api from "../../../APICalls/index";
import { ChangeSnackbar } from "../SnackbarActions/SnackbarActions";
import { handleSnackbar } from "../../../helpers/SnackbarHelper/SnackbarHelper";
import { Dispatch } from "redux";
import { statusHandler } from "../../../helpers/HelperFunction";
export type MasjidDataType = {
  masjidName: string;
  description: string;
  address: string;
  location: {
    type: string;
    coordinates: number[];
  };
  contact: string;
  externalLinks?: any;
};

export const addNewMasjid =
  (formData: MasjidDataType, navigate: any) => async (dispatch: Dispatch) => {
    try {
      const response = await api.addMasjid(formData);
      const data = { ...response.data, ...statusHandler(response.status) };
      if (data.success) {
        dispatch({ type: "ADD_MASJID", payload: response.data.data });

        navigate("/masjid/" + response.data.data._id);

        return data;
      }
      return data;
    } catch (error: any) {
      // handleSnackbar(true,"error",`${error.response.data.error.message}`,dispatch)

      let result = {
        success: false,
        message: "Please Enter Proper Details and Try Again",
      };
      return result;
    }
  };
