import { FETCH_MASJIDS } from '../../ActionType';
import * as api from '../../../APICalls/index';
import {ChangeSnackbar} from '../SnackbarActions/SnackbarActions'
import {handleSnackbar} from '../../../helpers/SnackbarHelper/SnackbarHelper';
import { Dispatch } from 'redux';

export const getFreezedAdmins = () => async(dispatch:Dispatch) => {
    try{
      const response = await api.fetchFreezeAdmins();
      if (response.status === 200) {
      
         let result = {
            success:true,
            message:'Successfully fetched admins',
            masjids:response.data.data,
         }
   
         return result
      }
      return  response.data; 
    }   
    catch(error:any)
   {
      handleSnackbar(true,"error",`Fetching Admin Failed: ${error.response.data.message}`,dispatch)
    return error.response.data;


   }
}