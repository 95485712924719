import React from "react";
import "./TopBar.css";
import { Link } from "react-router-dom";
type topBarProps = {
  title: string;
};
const TopBar = ({ title }: topBarProps) => {
  interface Admin {
    name: string;
    _id: string;
    title: string;
  }

  const adminString = localStorage.getItem("admin");
  const admin: Admin | null = adminString ? JSON.parse(adminString) : null;
  const noSpacePages = ["Admin", "Dashboard",];
  return (
    <>
      <div
        className={`top-bar-container-${
          noSpacePages.includes(title) ? "dashboard" : "all"
        }`}
      >
        <div className="TopBarLeft">
          <p className="page-top-title">{title}</p>
        </div>
        <div className="TopBarRight">
          <Link to={"/admins/" + admin?._id} style={{ textDecoration: "none" }}>
            <img
              src="https://raw.githubusercontent.com/rehaanredkar15/SMILE-Social-Media/master/public/Assets/person/user.webp"
              className="topbarImg"
              alt="profileImage"
            />
          </Link>
          <Link to={"/admins/" + admin?._id} style={{ textDecoration: "none" }}>
            <div className="TopUserName">{admin && admin.name}</div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default TopBar;
