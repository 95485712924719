import { FETCH_LATEST_UPDATED_EVENTS } from "../../ActionType";
import { EventActionType } from "../../../../Types";

// eslint-disable-next-line import/no-anonymous-default-export
// const FetchMasjidsByAdminReducer = (AdminMasjids:Masjid[] = [], action: Action):Masjid[] => {
const FetchLatestEventsReducer = (
  LatestEvents: Event[] = [],
  action: EventActionType
): Event[] => {
  switch (action.type) {
    case FETCH_LATEST_UPDATED_EVENTS:
      return action.payload;

    default:
      return LatestEvents;
  }
};

export default FetchLatestEventsReducer;
