import { AUTH_LOGIN } from "../../ActionType";
import * as api from "../../../APICalls/index";
import { Dispatch } from "redux";
import { UserActionType } from "../../../../Types";
// import {Redirect }from  'react-router-dom';
// import jwt_decode from "jwt-decode";
// import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions';

type formDateType = {
  userId: string;
  token: string | undefined;
  password: string | undefined;
};
export const VerifyingTwoFactorAuth =
  (formData: formDateType) => async (dispatch: Dispatch) => {
    try {
      const response = await api.VerifyingTwoFactorAuth(formData);

      if (response.status === 200) {
        //cookies 1

        localStorage.setItem("authTokens", JSON.stringify(response.data.token));

        dispatch({ type: "AUTH_LOGIN", payload: response.data.user });

        window.location.reload();

        let result = {
          data: response.data.user,
          success: true,
          message: "Logged In Successfully",
        };

        return result;
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data) {
        let result = {
          success: false,
          TwoFAUser: false,
          error: `Failed to Login`,
          message: error.response.data.data.error,
        };
        return result;
      }
      return error.response;
    }
  };
