import React from "react";
import { announceInputType } from "../AnnouncementFormComponent/AnnouncementFormComponent";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";

type PreviewProps = {
  announcementData: announceInputType;
  onCancel: () => void;
  onSubmit: () => void;
};

const AnnouncementPreviewComponent: React.FC<PreviewProps> = ({
  announcementData,
  onCancel,
  onSubmit,
}) => {
  return (
    <Dialog
      className="announcement-preview-modal"
      open={true}
      onClose={onCancel}
    >
      <DialogTitle style={{ textAlign: "center" }}>
        Announcement Preview
      </DialogTitle>
      <DialogContent style={{ borderRadius: "30px", textAlign: "center" }}>
        <DialogContentText>
          <Typography
            variant="h6"
            style={{ marginBottom: "15px", fontWeight: "bold" }}
          >
            Title: {announcementData.title}
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            Body: {announcementData.desc}
          </Typography>

          <Typography
            variant="subtitle1"
            style={{ fontWeight: "bold", borderBottom: "2px solid gray" }}
          >
            Selected Masjids:
          </Typography>
          <List style={{ textAlign: "center" }}>
            {announcementData.selectedMasjids.map((masjid, index) => (
              <ListItem key={index}>
                <ListItemText>
                  {index + 1}. {masjid.masjidName}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button onClick={onSubmit} color="primary" variant="contained">
          Submit
        </Button>
        <Button onClick={onCancel} color="secondary" variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AnnouncementPreviewComponent;
