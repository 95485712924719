import React, { SyntheticEvent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ChangeSnackbar } from "../../Redux/Actions/SnackbarActions/SnackbarActions";

import MuiAlert, { AlertProps } from "@mui/material/Alert";

import { useAppSelector, useAppThunkDispatch } from "../../Redux/hooks";
import { Snackbar, SnackbarCloseReason } from "@mui/material";

const Alert = React.forwardRef(function Alert(
  props: AlertProps,
  ref: React.Ref<HTMLDivElement>
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const CustomizedSnackbars = () => {
  const classes = useStyles();
  const dispatch = useAppThunkDispatch();
  const snackbarOpen = useAppSelector(
    (state) => state.snackBarState.snackbarOpen
  );
  const snackbarType: any = useAppSelector(
    (state) => state.snackBarState.snackbarType
  );
  const snackbarMessage = useAppSelector(
    (state) => state.snackBarState.snackbarMessage
  );

  const snackbarDetails = {
    snackbarOpen: false,
    snackbarMessage: snackbarMessage,
    snackbarType: snackbarType,
  };

  const handleClose = (
    event: Event | SyntheticEvent<any, Event>,
    reason: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(ChangeSnackbar(snackbarDetails));
  };
  const handleCloseAlert = (event: SyntheticEvent<Element, Event>) => {
    dispatch(ChangeSnackbar(snackbarDetails));
  };



  return (
    <div className={classes.root}>
  
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={10000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={snackbarType}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CustomizedSnackbars;
