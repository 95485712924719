import axios from "axios";
import { AuthTokens } from "../../Types";
import { getAccessToken } from "../helpers/HelperFunction";

const API = axios.create({ baseURL: import.meta.env.VITE_CLIENT_BASE_URL });
API.interceptors.request.use(
  async (req) => {
    //cookies block
    if (localStorage.getItem("authTokens")) {
      const authTokensString = localStorage.getItem("authTokens");
      const token: AuthTokens = authTokensString
        ? JSON.parse(authTokensString)
        : null;

      if (getAccessToken()) {
        req.headers.Authorization = `Bearer ${getAccessToken()}`;
      } else {
        req.headers.Authorization = `Bearer ${token.token}`;
      }
    }
    req.headers["X-Request-Origin"] = `connectmazjid-portal`;

    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 409) {
      localStorage.clear();
      window.location.reload();
    }
    // Return the error as is
    return Promise.reject(error);
  }
);

export const fetchMasjidById = (masjidId: string) =>
  API.get(`/masjid/get-masjid-by-id/` + masjidId);
export const createWidget = (selectedAssetType: string, masjidId: string) =>
  API.get(`/auth/api-key/widget/${selectedAssetType}/${masjidId}`);
export const fetchEventWithMasjidId = (masjidId: string) =>
  API.get(`/event/get-events-by-masjid-id/` + masjidId);

export const fetchSpecialTimingsWithMasjidId = (masjidId: string) =>
  API.get(`/special-timing/${masjidId}/get-all`);
