import React, { useState, useEffect, useRef, SetStateAction } from "react";

// import TopBar from "../../FeedComponents/TopBar/TopBar";
// import AllAdminsTable from "../../PageComponents/AllAdminsTable/AllAdminsTable";
// import { useSelector } from "react-redux";
import "./AnnouncementFormComponent.css";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Switch from "@mui/material/Switch";
import { CircularProgress } from "@material-ui/core";
import { AnnouncementNotification } from "../../../Redux/Actions/NotificationActions/AnnouncementNotificationAction";
import { getMasjids } from "../../../Redux/Actions/MasjidActions/FetchingMasjids";
// import { getAdminMasjids } from "../../../Redux/Actions/MasjidActions/FetchingMasjidByAdmin.js";
import { getStaleMasjids } from "../../../Redux/Actions/MasjidActions/FetchingStaleMasjids";
// import { FetchingAdmins } from "../../../Redux/Actions/AdminActions/FetchingAdmins";
import { handleSnackbar } from "../../../helpers/SnackbarHelper/SnackbarHelper";
import { useAppSelector, useAppThunkDispatch } from "../../../Redux/hooks";
import { AdminInterFace } from "../../../Components/FeedComponents/Feed/Feed";
import { AnnouncementType, Masjid } from "../../../../Types";
import { ResponseType } from "../../../../Types";
import { toast } from "react-hot-toast";
import MasjidsAutocomplete from "../../../Pages/Shared/MasjidsAutocomplete/MasjidsAutocomplete";
import { Modal } from "@mui/material";
import AnnouncementPreviewComponent from "../AnnouncementPreviewComponent/AnnouncementPreviewComponent";
import CustomAlert from "../../../Pages/Shared/CustomAlert/CustomAlert";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import OTPDialog from "../../../Pages/Shared/OTPDialog/OTPDialog";
import { adminFromLocalStg } from "../../../helpers/AdminFromLocalStorage/AdminFromLocalStorage";
import { AnnouncementOTPVerifierAction } from "../../../Redux/Actions/NotificationActions/AnnouncementOTPVerifierAction";
export type announceInputType = {
  title: string;
  desc: string;
  selectedMasjids: Masjid[];
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

type PropsType = {
  setViewingAnnouncement: (value: boolean) => void;
};

const defaultAnnouncementInfo = {
  title: "",
  desc: "",
  selectedMasjids: [],
};
const AnnouncementFormComponent = ({ setViewingAnnouncement }: PropsType) => {
  const [openOTPModal, setOpenOTPModal] = useState<boolean>(false);
  const [admin, setAdmin] = useState<AdminInterFace>(adminFromLocalStg());
  const [isVerifying, setIsVerifying] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(""); // Assuming you have state for email

  const handleOtpApiRequest = (otp: string) => {
    setIsVerifying(true);

    let formData = {
      otp: String(otp),
    };

    const res = dispatch(AnnouncementOTPVerifierAction(formData));
    res.then((result: any) => {
      if (result.success) {
        toast.success(result.message);
      } else {
        toast.error(`Failed To Verify OTP.`);
      }
      setIsVerifying(false);
      setOpenOTPModal(false);
    });
  };

  const [isTriggering, setisTriggering] = useState(false);
  const [SelectedAll, setSelectedAll] = useState(false);

  const [showPreview, setShowPreview] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [announcementData, setAnnouncementData] = useState<announceInputType>(
    defaultAnnouncementInfo
  );
  const dispatch = useAppThunkDispatch();
  const staleMasjids = useAppSelector((state) => state.staleMasjids);
  const masjids = useAppSelector((state) => state.masjids);

  useEffect(() => {
    if (admin?.role === "superadmin" || admin?.role === "admin") {
      masjids.length === 0 && dispatch(getMasjids("createdAt", "asc"));
      staleMasjids.length === 0 &&
        dispatch(getStaleMasjids("updatedAt", "asc"));
    }
  }, []);

  const handleChange = (masjid: Masjid[] | null) => {
    setAnnouncementData({
      ...announcementData,
      selectedMasjids: masjid ? masjid : [],
    });
  };

  const handleMasjidToggle = () => {
    if (SelectedAll) {
      setSelectedAll(false);
    } else {
      setSelectedAll(true);
    }
  };

  const handleAnnoucementNetworkCall = () => {
    if (SelectedAll) {
      setShowConfirmation(false);
      setOpenOTPModal(true);
      return;
    }
    const Ids = announcementData.selectedMasjids.map((item) => item._id);
    let uploadData = {
      title: announcementData.title,
      body: announcementData.desc,
      masjidIds: Ids,
      priorityType: "normal",
    };

    // Show toast loader while API call is in progress
    const loadingToastId = toast.loading("Sending notification...");

    const data = dispatch(AnnouncementNotification(uploadData));
    data.then(function (result: ResponseType) {
      if (result.success) {
        // Close loading toast
        toast.dismiss(loadingToastId);
        setShowConfirmation(false);
        setOpenOTPModal(true);
      } else {
        // Close loading toast
        toast.dismiss(loadingToastId);

        setisTriggering(false);
        handleSnackbar(
          true,
          "warning",
          "Failed to Send the Notification to the Application",
          dispatch
        );
      }
      setShowConfirmation(false);
    });
  };

  const handleTriggerNotification = () => {
    setisTriggering(true);
    if (!SelectedAll) {
      if (
        announcementData.title &&
        announcementData.desc &&
        announcementData.selectedMasjids.length > 0
      ) {
        // handleAnnoucementNetworkCall();
        setShowPreview(true);
      } else {
        setisTriggering(false);
        handleSnackbar(
          true,
          "warning",
          "Please Enter all Details Properly",
          dispatch
        );
      }
    } else if (SelectedAll) {
      if (announcementData.title && announcementData.desc && SelectedAll) {
        // handleAnnoucementNetworkCall();
        setShowPreview(true);
      } else {
        setisTriggering(false);
        handleSnackbar(
          true,
          "warning",
          "Please Enter all Details Properly",
          dispatch
        );
      }
    } else {
      setisTriggering(false);

      handleSnackbar(
        true,
        "warning",
        "Couldnt Send the Notification : Something Went Wrong ",
        dispatch
      );
    }
  };

  const handleClick = () => {
    setViewingAnnouncement(true);
  };

  const handleCancelOrSubmit = (isSubmit = false) => {
    setShowPreview(false);
    setisTriggering(false);
    if (isSubmit) setShowConfirmation(true);
  };

  return (
    <>
      <div className="AnnouncementContainer">
        <div className="AnnouncementContainerBody">
          <TextField
            autoFocus
            margin="dense"
            id="name"
            autoComplete="Off"
            error={isTriggering && !announcementData.title}
            helperText={
              isTriggering && !announcementData.title ? "Manditory field" : " "
            }
            label="Announcement Title"
            onChange={(e) => {
              setAnnouncementData({
                ...announcementData,
                title: e.target.value,
              });
            }}
            sx={{ marginBottom: 3 }}
            type="text"
            fullWidth
            variant="outlined"
          />
          <TextField
            id="outlined-required"
            error={isTriggering && !announcementData.desc}
            helperText={
              isTriggering && !announcementData.desc ? "Manditory field" : " "
            }
            variant="outlined"
            multiline
            rows={5}
            autoComplete="Off"
            onChange={(e) => {
              setAnnouncementData({
                ...announcementData,
                desc: e.target.value,
              });
            }}
            label="Announcement Body"
            fullWidth
            type="email"
            sx={{ marginBottom: 3 }}
          />

          <span className="itemKey">
            Choose All Masjids
            <Switch
              checked={SelectedAll}
              onChange={handleMasjidToggle}
              name="loading"
              color="primary"
            />
          </span>
          {!SelectedAll && (
            <MasjidsAutocomplete
              handleChange={handleChange}
              isMultiple={true}
            />
          )}
          {!announcementData.selectedMasjids.length && !SelectedAll ? (
            <span style={{ color: "red" }}>
              Choose Masjids Please Before triggering
            </span>
          ) : (
            <> </>
          )}
          <button className="ButtonToggle" onClick={handleTriggerNotification}>
            <span className="text">
              {isTriggering ? (
                <CircularProgress size="20px" />
              ) : (
                "Triggering Announcement"
              )}
            </span>
          </button>
          <div className="AnnouncementTtitleContainerForm">
            <button className="ButtonToggleViewAll" onClick={handleClick}>
              View All Announcements
            </button>
          </div>
        </div>
      </div>
      <Modal open={showPreview} onClose={() => setShowPreview(false)}>
        <AnnouncementPreviewComponent
          announcementData={announcementData}
          onCancel={() => handleCancelOrSubmit(false)}
          onSubmit={() => handleCancelOrSubmit(true)}
        />
      </Modal>
      <ConfirmationModal
        open={showConfirmation}
        onYes={() => handleAnnoucementNetworkCall()}
        onNo={() => setShowConfirmation(false)}
      />

      <OTPDialog
        open={openOTPModal}
        email={admin.email}
        setOpen={setOpenOTPModal}
        handleOtpApiRequest={handleOtpApiRequest}
        isVerifying={isVerifying}
      />
    </>
  );
};

export default AnnouncementFormComponent;
