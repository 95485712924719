import { Dispatch } from "redux";
import * as api from "../../../APICalls/index";
import { ChangeSnackbar } from "../SnackbarActions/SnackbarActions";
import { statusHandler } from "../../../helpers/HelperFunction";

type updatedDataType = {
  name: string;
  email: string;
  role: string;
};
export const AddingNewAdmin =
  (upadatedData: updatedDataType) => async (dispatch: Dispatch<any>) => {
    try {
      let { data, status } = await api.RegisterAdmin(upadatedData);
      data = { ...data, ...statusHandler(status) };
      if (data.success) {
        const snackbarDetails = {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Added New Admin",
        };

        dispatch(ChangeSnackbar(snackbarDetails));
        return data;
      }
      return data;
    } catch (error: any) {
      const errorRes = error?.response?.data;
      const isDuplicate = errorRes?.data?.error?.includes("uplicate");

      const snackbarFailureDetails = {
        snackbarOpen: true,
        snackbarType: "error",
        snackbarMessage: isDuplicate
          ? "User email already Exist . "
          : errorRes
          ? errorRes.message
          : "Adding Admin Failed",
      };

      dispatch(ChangeSnackbar(snackbarFailureDetails));
    }
  };
