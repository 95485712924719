
import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const WarningComponent: React.FC<{ role: string }> = ({ role }) => {
  const alertStyle: React.CSSProperties = {
    marginBottom: "16px",
    marginLeft: "25px",
    backgroundColor: "#FF6F00", // Deep Orange color for warning
    color: "#FFFFFF", // White text color
    borderRadius: "12px", // Larger rounded corners
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Box shadow for depth
  };

  const titleStyle: React.CSSProperties = {
    fontWeight: "bold",
    fontSize: "1.5em",
    letterSpacing: "0.5px", // Added letter spacing for style
  };
  const roles = ["subadmin", "musaliadmin", "superadmin", "admin"];
  return (
    <>
      {roles.indexOf(role) > 1 ? null : (
        <Alert severity="info" style={alertStyle}>
          <AlertTitle style={titleStyle}>Important Notice</AlertTitle>
          This Masjid is managed by {role.toUpperCase()}. Please exercise
          caution when making any changes.
        </Alert>
      )}
    </>
  );
};

export default WarningComponent;
