import React from "react";
import MasjidTable from "../../../Components/PageComponents/MasjidTable/MasjidTable";
import PageContainer from "../../Shared/PageContainer/PageContainer";

const Masjids = () => {


  return (
    <>
      <PageContainer pl={"20px"} title={"Masjids"}>
        <div className="RightFeedContainer">
          <MasjidTable />
        </div>
      </PageContainer>
    </>
  );
};

export default Masjids;
