import * as api from "../../../APICalls/index";

type formDataType = {
  email: string|undefined;
  otp: Number|undefined;
  password: string|undefined;
};
export const ResettingGoogleVerify =
  (formData: formDataType) => async (dispatch: any) => {
    try {
      const response = await api.ResetGoogleVerify(formData);

      if (response.status === 200) {
        let result = {
          data: response.data.data,
          QRCode: response.data.data.qrCode,
          message: response.data.data.message,
          success: true,
        };

        return result;
      }
      return response.data.data;
    } catch (error: any) {
      if (error.response?.data) {
        let result = {
          success: false,
          QRCode: "",
          error: `Failed to Verify`,
          message: error.response?.data?.data?.error,
        };
        return result;
      }
      return error.response;
    }
  };
