import React from "react";
import "./MasjidNameAndLinks.css";
import webImg from "../../../Photos/Newuiphotos/Social_logos/Website.svg";
import facebookImg from "../../../Photos/Newuiphotos/Social_logos/Facebook.svg";
import whatAppImg from "../../../Photos/Newuiphotos/Social_logos/Whatsapp.svg";

interface MasjidNameAndLinksProps {
  masjidName?: string;
  masjidLinks: { name: string; url: string }[];
}

const getLogo = (type: string) => {
  switch (type) {
    case "Website":
      return webImg; // Adjust the path based on where you store your images
    case "Facebook":
      return facebookImg;
    case "Whatsapp":
      return whatAppImg;
    default:
      return "";
  }
};

const MasjidNameAndLinks: React.FC<MasjidNameAndLinksProps> = ({
  masjidName,
  masjidLinks,
}) => {
  return (
    <div className="masjid-name-and-links">
      <h2>{masjidName}</h2>
      <ul>
        {masjidLinks?.map((link, index) => (
          <li key={index}>
            <a
              className="logo-container"
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {index < 3 ? (
                <img
                  src={getLogo(link.name)}
                  alt={link.name}
                  className="link-logo"
                />
              ) : null}
              <span>{link.url}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MasjidNameAndLinks;