import React from "react";
import { useAppSelector } from "../../../Redux/hooks";
import SideBar from "../../../Components/FeedComponents/SideBar/SideBar";
import TopBar from "../../../Components/FeedComponents/TopBar/TopBar";
import "./page-container.css";
type propsType = {
  children: any;
  title: string;
  pl?: string;
  pt?: string;
};
const PageContainer = ({ children, title, pl, pt = "0px" }: propsType) => {
  const sidebarState = useAppSelector((state) => state.sidebarState);
  return (
    <div className="HomeContainer2">
      {sidebarState ? (
        <div className="LeftContainer">
          <SideBar />
        </div>
      ) : (
        <div className="LeftContainerClose">
          <SideBar />
        </div>
      )}
      <div className="page-container-right">
        <div>
          <TopBar title={title} />
          <div style={{ paddingLeft: pl, paddingTop: pt }}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default PageContainer;
