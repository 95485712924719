import axios from "axios";
import { AuthTokens } from "../../Types";
import { getAccessToken, getRefreshToken } from "../helpers/HelperFunction";

export const API = axios.create({
  baseURL: import.meta.env.VITE_ADMIN_BASE_URL,
});

const authTokensString = localStorage.getItem("authTokens");
const token: AuthTokens | null = authTokensString
  ? JSON.parse(authTokensString)
  : null;

const refreshToken = () => {
  //cookies 2

  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${getRefreshToken()}`;

  return axios
    .post(`${import.meta.env.VITE_ADMIN_BASE_URL}/auth/refresh-token`)
    .then((response: any) => {
      //cookies 1
      localStorage.setItem("authTokens", JSON.stringify(response.data.data));
      window.location.reload();
    })
    .catch((error: any) => {
      console.log(error);
    });
};

API.interceptors.request.use(
  async (req) => {
    //cookies =block
    if (token) {
      // const token = JSON.parse(localStorage.getItem("authTokens"));
      if (getAccessToken()) {
        req.headers.Authorization = `Bearer ${getAccessToken()}`;
      } else {
        req.headers.Authorization = `Bearer ${token.token}`;
      }
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      return refreshToken().then(() => {
        const config = error.config;
        //cookies 2
        // const data = JSON.parse(localStorage.getItem("authTokens"));
        config.headers["Authorization"] = `Bearer ${getAccessToken()}`;
        return axios.request(config);
      });
    } else if (error.response && error.response.status === 409) {
      localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

// New APIS
export const fetchMasjids = (sortParam: string, sortOrder: string) =>
  API.get(`/masjid/get-masjids`, {
    params: { sortBy: sortParam, sortIn: sortOrder },
  });
export const createWidget = (selectedAssetType: string, masjidId: string) =>
  API.get(`/auth/api-key/widget/${selectedAssetType}/${masjidId}`);

export const addMasjid = (formData: any) =>
  API.post(`/masjid/add-masjid`, formData);

export const addMasjidMedia = (formData: any, masjidId: string) =>
  API.put(`/media/${masjidId}/upload`, formData);

export const deleteMasjidMedia = (mediaId: string, masjidId: string) =>
  API.delete(`/media/${masjidId}/delete/` + mediaId);
export const deleteMasjidProfileMedia = (masjidId: string) =>
  API.delete(`/masjid/${masjidId}/profile/delete`);
export const freezeMasjid = (masjidId: string, formData: any) =>
  API.post(`/masjid/${masjidId}/freeze`, formData);
export const fetchLatestUpdatedEvents = (limit: number, page: number) =>
  API.get(`/event/get-latest-edited-events`, {
    params: {
      limit: `${limit}`,
      page: `${page}`,
    },
  });
type formDataType = {
  email: string;
  password: string;
};
export const LoginAdmin = (formData: formDataType, captcha: string) =>
  API.post(`/auth/login`, formData, {
    params: {
      "g-recaptcha-response": captcha,
    },
  });
export const VerifyingTwoFactorAuth = (formData: any) =>
  API.post(`/auth/two-factor-auth/verify`, formData);
export const setPasswordNewUser = (formData: any) =>
  API.post(`/auth/verify-new-user`, formData);
export const ActivatingTwoFactorAuth = () =>
  API.put(`/auth/two-factor-auth/activate`);
export const ResetGoogleAuth = (email: string) =>
  API.put(`/auth/two-factor-reset/reset`, null, {
    params: {
      email: email,
    },
  });
export const ResetGoogleVerify = (formData: any) =>
  API.put(`/auth/two-factor-reset/verify-otp`, formData);
export const getAllAdmin = () => API.get(`/admin/get-all-admins`);
export const getAdminByName = (adminName: string) =>
  API.get(`/admin/get-admin-by-name/` + adminName);
export const getAdminById = (adminId: string) =>
  API.get(`/admin/get-admin-by-id/` + adminId);
export const fetchFreezeAdmins = () => API.get(`/admin/get-freezed`);
export const fetchFreezeMasjids = () => API.get(`/masjid/get-freezed`);
export const addingRandomDaysTimings = (formData: any, masjidId: string) =>
  API.post(`/timing/${masjidId}/random-timings/add`, formData);
export const addSolarTimings = (formData: any, masjidId: string) =>
  API.post(`/timing/${masjidId}/solar/range`, formData);
export const updateMinimumTimingsOfSingleDay = (
  formData: any,
  masjidId: string
) => API.put(`/timing/${masjidId}/random-timing/update `, formData);
export const deleteMinimumTimingsOfSingleDay = (
  formData: any,
  masjidId: string
) => API.put(`/timing/${masjidId}/random-timing/delete `, formData);

export const RegisterAdmin = (formData: any) =>
  API.post(`/admin/register-user`, formData);
export const freezeAdmin = (formData: any) =>
  API.post(`/admin/freeze`, formData);
export const updateAdminById = (formData: any, adminId: string) =>
  API.put(`/admin/update-user-by-id/${adminId}`, formData);
export const updateMasjidInAdminById = (formData: any) =>
  API.post(`/admin/masjid/add`, formData);
export const deleteMasjidInAdminById = (formData: { user: string }) =>
  API.put(`/admin/masjid/delete`, formData);
export const ResendingInvitation = (email: string) =>
  API.post(`/admin/resend-confirmation-email`, null, {
    params: {
      email: `${email}`,
    },
  });
export const ForgotPassword = (formData: any) =>
  API.post(`/auth/password/forgot`, formData);
export const ResetPassword = (formData: any) =>
  API.post(`/auth/password/reset`, formData);
export const triggeringAnnouncement = (formData: any) =>
  API.post(`/notification/announcement`, formData);
export const triggeringAnnouncementOTPVerifier = (otp: { otp: string }) =>
  API.post(`/notification/announcement/verify`, otp);
export const fetchingAnnouncement = () =>
  API.get(`/notification/announcement/get`);
export const triggeringEventAnnouncement = (
  masjidId: string,
  eventId: string,
  action: any,
  formData: any
) => API.post(`/notification/event/${masjidId}/${eventId}/${action}`, formData);

export const unfreezeAdmin = (id: string) =>
  API.delete(`/freeze/admin/`, {
    params: {
      id,
    },
  });

export const fetchAdminMasjids = (id: string) =>
  API.get(`/masjid/getMasjidsByAdmin/` + id, {
    params: { sortOrder: "asc" },
  });
