import React from "react";
import SideBar from "../../../Components/FeedComponents/SideBar/SideBar";

import FreezeMasjidTable from "../../../Components/FeedComponents/FreezeMasjidTable/FreezeMasjidTable";
import { useSelector } from "react-redux";
import TopBar from "../../../Components/FeedComponents/TopBar/TopBar";
import { useAppSelector } from "../../../Redux/hooks";
import PageContainer from "../../Shared/PageContainer/PageContainer";

const FreezeMasjids = () => {
  const sidebarState = useAppSelector((state) => state.sidebarState);

  return (
    <>
      <PageContainer pt={"60px"} title={"Freezed Masjids"}>
        <div className="RightFeedContainer">
          <FreezeMasjidTable />
        </div>
      </PageContainer>
    </>
  );
};

export default FreezeMasjids;
