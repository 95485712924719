import { FETCH_MASJIDS } from "../../ActionType";
import * as api from "../../../APICalls/index";
import {handleSnackbar} from '../../../helpers/SnackbarHelper/SnackbarHelper';
import { Dispatch } from "redux";

export const getFreezedMasjids = () => async (dispatch:Dispatch) => {
  try {
    const response = await api.fetchFreezeMasjids();

    if (response.status === 200) {
      
      let result = {
         success:true,
         message:'Successfully fetched masjids',
         masjids:response.data.data,
      }

      return result
    }
    return response.data;

  } catch (error:any) {
     handleSnackbar(true,"error",`Fetching Masjid Failed: ${error.response.data.message}`,dispatch)
      return error.response.data;

  }
};
