import React, { useEffect, useState } from "react";
import { FetchingAnnouncementNotification } from "../../../Redux/Actions/NotificationActions/FetchingAnnouncement";
import "./AnnouncementCarousel.css";
import { FetchingAdmins } from "../../../Redux/Actions/AdminActions/FetchingAdmins";
import moment from "moment";
import { MdCancel } from "react-icons/md";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import { Admin, AnnouncementType } from "../../../../Types";
import { useAppThunkDispatch, useAppSelector } from "../../../Redux/hooks";
import { Box } from "@mui/material";

type AnnouncementCarouselComponentProps = {
  setViewingAnnouncement: (value: boolean) => void;
};

const AnnouncementCard = ({
  announcement,
  adminName,
}: {
  announcement: AnnouncementType;
  adminName: string;
}) => {
  return (
    <Card className="AnnouncementsCard">
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {announcement.title}
        </Typography>
        {/* <Typography variant="h6" gutterBottom>
          {announcement.masjids[0]?.masjidName}
        </Typography> */}
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          Announced on: {moment(announcement.createdAt).format("DD/MM/YYYY")}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Announced by: {adminName}
        </Typography>
        <Box className="announcements-body-tx">
          <Typography variant="body1" component="p">
            {announcement.body}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const AnnouncementCarouselComponent = ({
  setViewingAnnouncement,
}: AnnouncementCarouselComponentProps) => {
  const dispatch = useAppThunkDispatch();
  const [announcements, setAnnouncements] = useState<AnnouncementType[]>([]);
  const [announcementsByMasjid, setAnnouncementsByMasjid] = useState<
    AnnouncementType[]
  >([]);
  const admins = useAppSelector((state) => state.allAdmins);
  const [selectedOption, setSelectedOption] = useState("");
  const masjids = useAppSelector((state) => state.masjids);

  useEffect(() => {
    const fetchData = async () => {
      if (admins.length === 0) {
        await dispatch(FetchingAdmins());
      }

      const result = await dispatch(FetchingAnnouncementNotification());
      if (result) {
        setAnnouncements(result.data);
        setAnnouncementsByMasjid(result.data);
      }
    };

    fetchData();
  }, []);

  const handleGetNotifications = (id: string) => {
    // console.log("this si form announcements => ", announcements.find(item=>item.title==="riyad"));
    let res = announcements.filter(
      (item: AnnouncementType) => item.masjidId === id
    );
    console.log("res =>", res);
    setAnnouncementsByMasjid(res);
  };

  const handleClick = () => {
    setViewingAnnouncement(false);
  };

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    setSelectedOption(event.target.value as string);
    handleGetNotifications(event.target.value as string);
  };

  return (
    <div className="AnnouncementCarouselComponent">
      <div className="AnnouncementCarouselMainContainer">
        <div className="AnnouncementTitleContainer">
          <span className="AnnouncementsTitle">Past Announcements By You</span>
          <div>
            <Select
              value={selectedOption}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Select Masjid" }}
            >
              <MenuItem value="" disabled>
                Select an option
              </MenuItem>
              {masjids.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.masjidName}
                </MenuItem>
              ))}
            </Select>
          </div>
          <MdCancel
            data-testid="CancelIocnAnnouncements"
            className="CancelIocnAnnouncements"
            onClick={handleClick}
          />
        </div>

        <div className="AnnouncementsCarousel">
          {announcementsByMasjid?.length > 0 ? (
            announcementsByMasjid.map((item: AnnouncementType) => {
              const admin = admins.find(
                (admin: Admin) => admin._id === item?.createdBy
              );
              const adminName = admin ? admin.name : "Unknown";
              return (
                <AnnouncementCard
                  key={item._id}
                  announcement={item}
                  adminName={adminName}
                />
              );
            })
          ) : (
            <div className="EventsContainer">
              <h5 style={{ marginLeft: "10px" }}>
                Sorry!! No Announcements Found
              </h5>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnnouncementCarouselComponent;
