import * as api from "../../../APICalls/index";
// import {Redirect }from  'react-router-dom';
import jwt_decode from "jwt-decode";
import { ChangeSnackbar } from "../SnackbarActions/SnackbarActions";
import { Dispatch } from "redux";

type formDataType = {
  password: string;
  email: string;
  token: string;
  type: string;
};
export const resetPassword = (formData: formDataType) => async () => {
  try {
    const response = await api.ResetPassword(formData);

    if (response.status === 200) {
      let result = {
        data: response.data,
        success: true,
        message: "Password Reset Successfully",
      };

      return result;
    }
    return response.data;
  } catch (error: any) {
    let result = {
      success: false,
      error: `Failed to Login`,
      message: `Password Reset Failed : Invalid Token`,
    };

    return result;
  }
};
