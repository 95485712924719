import React from "react";
import {
  Avatar,
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
  },
  listItem: {
    borderRadius: "10px",
    display: "flex",
  },
  clientMessage: {
    // paddingTop:"10px",
    display: "flex",
    justifyContent: "flex-end",
    // flexDirection: "column",
  },
  messageBox: {
    // display: "flex",
    backgroundColor: "white",
    borderRadius: "16px",
    minWidth: "400px",
    // minHeight: "100px",
    padding: "10px",
    boxShadow:
      " 0px 4px 10px rgba(0, 0, 0, 0.3), 0px 4px 20px rgba(0, 0, 0, 0.2)",
    marginRight: "5px",

    // flexDirection: "column",
  },
  topSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
    borderBottom: "2px solid grey",
  },
  timeIcon: {
    display: "flex",
    alignItems: "center",
  },
  editIcon: {
    display: "flex",
    alignItems: "center",
    border: "2px solid green",
  },
  listItemRight: {
    justifyContent: "flex-end",
  },
  avatar: {
    backgroundColor: "white",
    color: "green",
  },
}));

interface MessageProps {
  message: string;
  createdAt: string;
  author: string;
  fromInternalTeam: boolean;
}

const Message: React.FC<MessageProps> = ({
  message,
  author,
  createdAt,
  fromInternalTeam,
}) => {
  console.log(author);
  const classes = useStyles();

  function formatDateTime(createdAt: string) {
    // Create a Date object
    const date = new Date(createdAt);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      // Handle invalid date, e.g., return empty strings or a default value
      return {
        formattedDate: "",
        formattedTime: "",
      };
    }

    // Format date as "month-day-year"
    const formattedDate = `${
      date.getMonth() + 1
    }-${date.getDate()}-${date.getFullYear()}`;

    // Format time as "12-hour AM/PM"
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };
    const formattedTime = new Intl.DateTimeFormat("en-US", options).format(
      date
    );

    return {
      formattedDate,
      formattedTime,
    };
  }

  const { formattedDate, formattedTime } = formatDateTime(createdAt);
  return (
    <div className={`${classes.root}`}>
      <ListItem>
        <Box className={`${classes.listItem}`}>
          <ListItemAvatar
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginRight: "7px",
            }}
          >
            <Avatar className={classes.avatar} style={{ marginRight: "8px" }}>
              {author.charAt(0).toUpperCase()}
            </Avatar>
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {author}
            </span>
          </ListItemAvatar>
          <Box className={classes.messageBox}>
            <div className={classes.topSection}>
              <div className={classes.timeIcon}>
                <AccessTimeIcon fontSize="small" />
                <Typography variant="caption" style={{ marginLeft: "5px" }}>
                  {formattedDate}
                </Typography>
              </div>
              <Typography variant="caption" style={{ marginLeft: "5px" }}>
                {formattedTime}
              </Typography>
            </div>
            {message}
          </Box>
        </Box>
      </ListItem>
    </div>
  );
};

export default Message;
