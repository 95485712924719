import { GET_ALL_ADMIN } from "../../ActionType";
import { Admin, AdminActionType } from "../../../../Types";

// eslint-disable-next-line import/no-anonymous-default-export
const FetchingAdminReducer = (
  admins: Admin[] = [],
  action: AdminActionType
) => {
  switch (action.type) {
    case GET_ALL_ADMIN:
      return action.payload;

    default:
      return admins;
  }
};

export default FetchingAdminReducer;
