import { FETCH_STALE_MASJIDS } from "../../ActionType";
import { Action, Masjid } from "../../../../Types";

const FetchStaleMasjidReducer = (
  staleMasjids: Masjid[] = [],
  action: Action
): Masjid[] => {
  switch (action.type) {
    case FETCH_STALE_MASJIDS:
      let masjidsWithEmail: Masjid[] = [];
      for (const singleMasjid of action.payload) {
        let emailString = "";
        for (const Link of singleMasjid.externalLinks) {
          if (Link.name !== "Email") continue;
          else if (Link.name === "Email") {
            emailString += (emailString.length > 0 ? ", " : "") + Link.url;
          }
        }
        masjidsWithEmail.push({ ...singleMasjid, email: emailString });
      }
   

      return masjidsWithEmail;

    default:
      return staleMasjids;
  }
};

export default FetchStaleMasjidReducer;
