import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { Provider } from "react-redux";
import Store from "./Redux/Store";
// import { StateContextProvider } from './contexts/StateContextProvider';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { StateContextProvider } from "./contexts/StateContextProvider";
import App from "./App";
import { ApolloProvider } from "@apollo/client";
import client from "./apolloClient";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ApolloProvider client={client}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <StateContextProvider>
        <Provider store={Store}>
          <App />
        </Provider>
      </StateContextProvider>
    </MuiPickersUtilsProvider>
  </ApolloProvider>
);
