import React, { useState } from "react";
import SideBar from "../../../Components/FeedComponents/SideBar/SideBar";

import { useSelector } from "react-redux";
import "./Announcement.css";
import AnnouncementFormComponent from "../../../Components/AnnouncementComponent/AnnouncementFormComponent/AnnouncementFormComponent";
import AnnouncementCarouselComponent from "../../../Components/AnnouncementComponent/AnnouncementCarouselComponent/AnnouncementCarouselComponent";
import TopBar from "../../../Components/FeedComponents/TopBar/TopBar";
import { useAppSelector } from "../../../Redux/hooks";
import PageContainer from "../../Shared/PageContainer/PageContainer";

const Announcement = () => {
  const sidebarState = useAppSelector((state) => state.sidebarState);
  const [ViewingAnnouncement, setViewingAnnouncement] = useState(false);

  return (
    <>
      <PageContainer title={"Announcement"}>
        <div className="RightAnnouncementContainer">
          {ViewingAnnouncement ? (
            <>
              <AnnouncementCarouselComponent
                setViewingAnnouncement={setViewingAnnouncement}
              />
            </>
          ) : (
            <>
              <AnnouncementFormComponent
                setViewingAnnouncement={setViewingAnnouncement}
              />
            </>
          )}
        </div>
      </PageContainer>
    </>
  );
};

export default Announcement;
