import { FETCH_ADMIN_MASJIDS } from "../../ActionType";
import { Action, Masjid } from "../../../../Types";

const FetchMasjidsByAdminReducer = (AdminMasjids:Masjid[] = [], action: Action):Masjid[] => {
  switch (action.type) {
    case FETCH_ADMIN_MASJIDS:
      return action.payload;

    default:
      return AdminMasjids;
  }
};

export default FetchMasjidsByAdminReducer;
