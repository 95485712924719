import React, { createContext, useContext, useState } from "react";

export type StateContextType = {
  results: any[];
  loading: boolean;
  searchTerm: string;
  getResults: (value: string) => Promise<void>;
  setSearchTerm: (term: string) => void;
};

const StateContext = createContext<StateContextType|undefined>(undefined);

export const StateContextProvider = ({ children }: any) => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const getResults = async (value:string) => {
    setLoading(true);

    try {
      await fetch(
        `https://api.locationiq.com/v1/autocomplete.php?key=pk.31d65a02fb607283b45c94780cafbbba&q=${value}&limit=6}`
      )
        .then((res) => res.json())
        .then((json) => setResults(json));
    } catch (error) {
      setResults([]);
    }

    setLoading(false);
  };

  return (
    <StateContext.Provider
      value={{ getResults, results, searchTerm, setSearchTerm, loading }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
