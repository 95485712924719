import { Dispatch } from 'redux';
import * as api from '../../../APICalls/index';
import {ChangeSnackbar} from '../SnackbarActions/SnackbarActions'

type UploadDataType={
  user: string | undefined;
  masjidId: string;}
export const UpdateMasjidInAdminById = (upadatedData:UploadDataType) => async(dispatch:Dispatch<any>) => {

  try{

    const { data } = await api.updateMasjidInAdminById(upadatedData);

    if(data.success)
    {
      const snackbarDetails = {
          snackbarOpen:true, 
           snackbarType:"success",
           snackbarMessage:"Update Admin",
         }
    
        dispatch(ChangeSnackbar(snackbarDetails));
       return data;
     }
   return data;
  }    
  catch(error:any)
 {
    const snackbarFailureDetails = {
       snackbarOpen:true, 
        snackbarType:"error",
        snackbarMessage:error.response.data ?error.response.data.message:"Updating Admin Failed",
      }
 
     dispatch(ChangeSnackbar(snackbarFailureDetails)); 
    // (error);

 }
}