import { CircularProgress } from "@mui/material";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
type propType = {
  isFetching: boolean;
  ForgotPas?: boolean;
  children?: ReactNode;
};
const AuthActionBtn = ({
  isFetching,
  ForgotPas = false,
  children,
}: propType) => {
  const btnStyle = {
    height: "50px",
    borderRadius: "10px",
    border: "none",
    backgroundColor: "#054635",
    color: "white",
    fontSize: "20px",
    fontWeight: 500,
    cursor: "pointer",
  };
  return (
    <>
      <button style={btnStyle} type="submit" disabled={isFetching}>
        {isFetching ? (
          <CircularProgress style={{ color: "white" }} size="20px" />
        ) : (
          `${ForgotPas ? "Forgot Password" : "Reset Password"}`
        )}
      </button>
      <span
        style={{
          textAlign: "center",
          textDecoration: "none",
          color: "#1775ee",
        }}
      >
        {children ? children : <Link to="/login"> Back to Login</Link>}
      </span>
    </>
  );
};

export default AuthActionBtn;
