import React, { ChangeEvent } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import { Link } from "react-router-dom";
import { darken, lighten } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useAppSelector } from "../../../Redux/hooks";
import { CellDataType } from "../../../../Types";
import "./StaleMasjidTable.scss";
const today = moment();

const userColumns: any = [
  {
    field: "masjidName",
    headerName: "Masjid Name",
    autoComplete: "off",
    width: 280,
    renderCell: (params: CellDataType) => {
      return (
        <Link
          to={"/masjid/" + params.id}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#054635" }}
        >
          {params.row.masjidName}
        </Link>
      );
    },
  },
  {
    field: "updatedAt",
    headerName: "Last Updated On",
    autoComplete: "off",
    width: 220,
    renderCell: (params: CellDataType) => {
      return (
        <div className="dataUpdatedAt">
          {moment(params.row.updatedAt).format("MM/DD/YYYY hh:mm a")}
        </div>
      );
    },
    valueGetter: (params: CellDataType) =>
      moment(params.row.updatedAt).format("MM/DD/YYYY hh:mm a"),
  },
  {
    field: "Days Before",
    headerName: "Days since last update",
    autoComplete: "off",
    width: 100,
    renderCell: (params: CellDataType) => {
      return (
        <div>
          {moment(today.format()).diff(moment(params.row.updatedAt), "days")}
        </div>
      );
    },
    valueGetter: (params: CellDataType) =>
      moment(today.format()).diff(moment(params.row.updatedAt), "days"),
  },
  {
    field: "lastEditor",
    headerName: "Last Updated By",
    width: 200,
    editable: "never",
    filterable: true,
    renderCell: (params: CellDataType) => {
      return <div>{params.row.lastEditor?.name}</div>;
    },
    valueGetter: (params: CellDataType) => params.row.lastEditor?.name,
  },
  {
    field: "contact",
    headerName: "Contact",
    autoComplete: "off",
    width: 170,
    filterable: true,
  },
  {
    field: "address",
    headerName: "Address",
    width: 240,
    autoComplete: "off",
    filterable: true,
  },
  {
    field: "email",
    headerName: "Email",
    width: 250,
    autoComplete: "off",
    filterable: true,
  },

  // field: "email",
  // headerName: "Email",
  // autoComplete: "off",
  // width: 280,
  // renderCell: (params: CellDataType) => {
];

const getBackgroundColor = (color: any, mode: any) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);
interface CustomToolbarProps {
  filters: Record<string, any>;
  onFiltersChange: any;
}
const StaleMasjidTable = () => {
  const staleMasjids = useAppSelector((state) => state.staleMasjids);

  return (
    <div className="StaleMasjidTableContainer">
      <Box
        sx={{
          height: 400,
          width: "100%",
          "& .super-app-theme--Open": {
            bgcolor: (theme) =>
              getBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode
              ),
          },
          "& .super-app-theme--Filled": {
            bgcolor: (theme) =>
              getBackgroundColor(theme.palette.error.main, theme.palette.mode),
          },
          "& .super-app-theme--PartiallyFilled": {
            bgcolor: (theme) =>
              getBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode
              ),
          },
          "& .super-app-theme--Rejected": {
            bgcolor: (theme) =>
              getBackgroundColor(theme.palette.error.main, theme.palette.mode),
          },
        }}
      >
        <div className="dashboard-masjid-table">
          <DataGrid
            loading={staleMasjids.length <= 0}
            rows={staleMasjids}
            columns={userColumns}
            pageSize={12}
            getRowId={(row) => row._id}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            getRowClassName={(params) => {
              if (
                moment(today.format()).diff(
                  moment(params.row.updatedAt),
                  "days"
                ) > 7
              ) {
                return `super-app-theme--Filled`;
              } else {
                return `super-app-theme--Open`;
              }
            }}
          />
        </div>
      </Box>
    </div>
  );
};

export default StaleMasjidTable;
