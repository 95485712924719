import { Dispatch } from 'redux';
import * as api from '../../../APICalls/index';
import {handleSnackbar} from '../../../helpers/SnackbarHelper/SnackbarHelper';

export const getMasjids = (sortParam:string,sortOrder:string) => async(dispatch:Dispatch) => {


    try{
    
      const response = await api.fetchMasjids(sortParam,sortOrder);

      if (response.status === 200) 
      {
         dispatch({type:"FETCH_MASJIDS" , payload: response.data.data});

         return response.data;
       }
   
     return response.data;

     
    }    
    catch(error:any)
   {
      if (error.response.status === 401){
          localStorage.clear();
      window.location.reload();
      } 
      

      handleSnackbar(true,"error",`Fetching Masjid Failed: ${error.response.data.message}`,dispatch)
      return error.response.data;

   }
}