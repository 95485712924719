import React, { useEffect, useState } from "react";
import WidgetComponent from "../../MasjidComponent/WidgetComponent";
import WarningComponent from "../MasjidTimings/WarningComponent/WarningComponent";
import { useAppThunkDispatch } from "../../../Redux/hooks";
import { adminFromLocalStg } from "../../../helpers/AdminFromLocalStorage/AdminFromLocalStorage";
import { useNavigate } from "react-router-dom";
import "./masjidTop.css";
import { FetchMasjidById } from "../../../Redux/Actions/MasjidActions/FetchMasjidById";
import { Masjid } from "../../../../Types";
import toast from "react-hot-toast";
import BackButton from "../../../Pages/ProfilePages/BackBtn/BackBtn";
import FreezeMasjid from "../../MasjidComponent/FreezeMasjid";
import MasjidNameAndLinks from "../MasjidNameAndLinks/MasjidNameAndLinks";
import CRMModal from "../../CRMComponent/CRMModal";

const MasjidTop = ({ consumerMasjidId }) => {
  const [currentMasjid, setCurrentMasjid] = useState<Masjid>();
  const [loggedInUserInfo] = useState(adminFromLocalStg());

  const dispatch = useAppThunkDispatch();
  useEffect(() => {
    fetchMasjid();
  }, []);
  const fetchMasjid = () => {
    if (consumerMasjidId) {
      const response = dispatch(FetchMasjidById(consumerMasjidId));
      response.then(function (result: any) {
        if (result.success) {
          setCurrentMasjid(result.data);
        } else {
          const message = result?.message
            ? "Failed to Load Masjid Details : " + result.message
            : "Failed to Load Masjid Details : Internet or Server Issue ";
          toast.error(message);
        }
      });
    }
  };

  return (
    <div className="masjid-top">
      <div className="titleHead">
        {(loggedInUserInfo?.role === "superadmin" ||
          loggedInUserInfo?.role === "admin") && (
          <>
            <FreezeMasjid masjidId={consumerMasjidId} />
          </>
        )}
        <CRMModal consumerMasjidId={consumerMasjidId} />
        <MasjidNameAndLinks
          masjidName={currentMasjid?.masjidName}
          masjidLinks={currentMasjid?.externalLinks}
        />
        <WidgetComponent
          masjidId={consumerMasjidId}
          MasjidName={currentMasjid?.masjidName}
          latitude={currentMasjid?.location.coordinates[1]}
          longitude={currentMasjid?.location.coordinates[0]}
        />

        {/* <WarningComponent role={"subadmin"} /> */}
        {currentMasjid?.lastEditor?.role ? (
          <WarningComponent role={currentMasjid.lastEditor.role} />
        ) : null}
      </div>
    </div>
  );
};

export default MasjidTop;
