import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MasjidPersonsList from "./MasjidList/MasjidList";
import ChatWindow from "./ChatWindow/ChatWindow";
import MessageInput from "./MessageInput/MessageInput";
import PageContainer from "../../Pages/Shared/PageContainer/PageContainer";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import EditIcon from "@mui/icons-material/Edit";
import { useMutation, useQuery } from "@apollo/client";
import { GET_MEMBERS, GET_MESSAGES } from "../../graphql/queries";
import { adminFromLocalStg } from "../../helpers/AdminFromLocalStorage/AdminFromLocalStorage";
import {
  CREATE_MEMBER,
  CREATE_MESSAGE,
  UPDATE_MEMBER,
} from "../../graphql/mutations";
import { CreateMemberInput, CreateMemberResponse } from "../../../Types";
import toast from "react-hot-toast";
import SearchFilter from "./SearchFilter/SearchFilter";
const useStyles = makeStyles(() => ({
  addPersonInput: {
    borderRadius: "8px",
    padding: "10px",
    backgroundColor: "white",
    boxShadow:
      "0px 4px 10px rgba(0, 0, 0, 0.3), 0px 4px 20px rgba(0, 0, 0, 0.2)",
  },
  messageContainer: {
    height: "500px",
    display: "flex",
    flexDirection: "column",
  },
  messageTop: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    margin: "auto 10px",
  },
  personDetails: {
    "& h5": {
      margin: "15px 0 0 0",
      fontSize: "15px",
    },
    "& p": {
      margin: "0px",
      fontSize: "14px",
    },
    "& .MuiGrid-item": {
      marginBottom: "12px",
    },
  },
  dialogBox: {
    "& .MuiPaper-root": {
      minWidth: "30%",
      borderRadius: "10px",
    },
  },
}));

interface MessageData {
  message: string;
  author: string;
  fromInternalTeam: boolean;
}

export interface Person {
  _id: string;
  personName: string;
  email: string;
  phoneNumber: string;

  designation: string;
}
const initialPerson: Person = {
  id: 0,
  personName: "",
  email: "",
  phoneNumber: "",
  designation: "",
};

const CRM: React.FC = ({ consumerMasjidId }: any) => {
  const [selectedPerson, setSelectedPerson] = useState<Person>(initialPerson);
  const [inputMessage, setInputMessage] = useState<string>("");
  const [addEditModalOpen, setAddEditModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isViewing, setIsViewing] = useState(false);
  const [reload, setReload] = useState(false);
  const [reloadMsg, setReloadMsg] = useState(false);
  const [personName, setPersonName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [designation, setDesignation] = useState("");
  // const loggedInUserInfo = adminFromLocalStg();
  // const consumerMasjidId = loggedInUserInfo.masjids[0];
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [nameError, setNameError] = useState(false);
  const [selectedPersonId, setSelectedPersonId] = useState("");
  const [editName, setEditName] = useState("");
  const [editEmail, setEditEmail] = useState("");
  const [editPhone, setEditPhone] = useState("");
  const [editDesignation, setEditDesignation] = useState("");

  const [createMessage, { loading: messageLoading }] =
    useMutation(CREATE_MESSAGE);

  console.log("SelectedPersonId", selectedPersonId);
  const handleSendMessage = () => {
    const input = {
      masjidId: consumerMasjidId,
      body: inputMessage,
    };

    // const loadingToastId = toast.loading("loading...");
    createMessage({
      variables: {
        message: {
          masjidId: consumerMasjidId,
          body: inputMessage,
        },
      },
    })
      .then((response) => {
        setReloadMsg(true);
        console.log("response => ", response);
        console.log("Message send:", response.data.createMessage);
        setInputMessage("");
        setTimeout(() => {
          setReloadMsg(false);
        }, 2000);
      })
      .catch((error) => {
        toast.dismiss();
        toast.error("Error sending message...");
        console.error("Error sending message:", error);
        setReloadMsg(true);
        setTimeout(() => {
          setReloadMsg(false);
        }, 2000);
      });
  };
  const { loading, error, data, refetch } = useQuery(GET_MESSAGES, {
    variables: { masjidId: consumerMasjidId },
  });

  const handleAddOpenModal = () => {
    setNameError(false);
    emptyStateHandler();
    setAddEditModalOpen(true);
  };
  const handleEditModalOpen = (e) => {
    e.stopPropagation();
    setNameError(false);
    setViewModalOpen(false);
    setAddEditModalOpen(true);
    setIsEditing(true);
  };
  const payload: CreateMemberInput = {
    personName,
    masjidId: consumerMasjidId,
    email,
    phoneNumber,
    designation,
    img: "", // or undefined if it's optional
  };
  // const CREATE_MEMBER_MUTATION = CREATE_MEMBER(payload);
  // const [createMember, { loading: memberLoading }] = useMutation(
  //   CREATE_MEMBER,
  //   { variables: {} }
  // );

  const [
    createMember,
    { loading: loadingMember, error: memberError, data: memberData },
  ] = useMutation(CREATE_MEMBER);

  const [
    updateMember,
    {
      loading: loadingUpdateMember,
      error: memberUpdateError,
      data: memberUpdateData,
    },
  ] = useMutation(UPDATE_MEMBER);

  const handleCloseAddEditModal = () => {
    setAddEditModalOpen(false);
    setIsEditing(false);
    setSelectedPersonId("");
  };
  const handleCloseViewModal = () => {
    setSelectedPersonId("");
    setViewModalOpen(false);
  };

  const emptyStateHandler = () => {
    setPersonName("");
    setEmail("");
    setPhoneNumber("");
    setDesignation("");
    handleCloseAddEditModal();
    setIsEditing(false);
  };
  const handleSavePerson = () => {
    if (personName) {
      setNameError(false);
      const loadingToastId = toast.loading("loading...");
      if (isEditing) {
        updateMember({
          variables: {
            input: {
              _id: selectedPersonId,
              personName: personName,
              email: email,
              phoneNumber: phoneNumber,
              designation: designation,
              img: "",
            },
          },
        })
          .then((response) => {
            setReload(true);
            toast.dismiss(loadingToastId);
            setTimeout(() => {
              setReload(false);
            }, 2000);
            toast.success("Successfully Updated Member");
          })
          .catch((error) => {
            toast.dismiss(loadingToastId);
            toast.error("Error Updating Member");
            setTimeout(() => {
              toast.dismiss();
            }, 2000);
            console.error("Error updating member:", error);
          });
        emptyStateHandler();
      } else {
        createMember({
          variables: {
            input: {
              personName: personName,
              masjidId: consumerMasjidId,
              email: email,
              phoneNumber: phoneNumber,
              designation: designation,
              img: "", // or undefined if it's optional
            },
          },
        })
          .then((response) => {
            setReload(true);
            toast.dismiss(loadingToastId);
            setTimeout(() => {
              setReload(false);
            }, 2000);
            toast.success("Successfully Created Member");
          })
          .catch((error) => {
            toast.dismiss(loadingToastId);
            toast.error("Error Creating Member");
            setTimeout(() => {
              toast.dismiss();
            }, 2000);
            console.error("Error creating member:", error);
          });
        emptyStateHandler();
      }
    } else {
      setNameError(true);
      toast.error("Please fill all the required fields!");
    }
  };

  const classes = useStyles();

  const selectedPersonHandler = (target: any, person: Person) => {
    // setAddEditModalOpen(true);
    setViewModalOpen(true);
    // setIsEditing(true);
    setIsViewing(true);
    setPersonName(person.personName);
    setEmail(person.email);
    setPhoneNumber(person.phoneNumber);
    setDesignation(person.designation);
    setSelectedPersonId(person._id);

    setSelectedPerson({
      personName: person.personName,
      email: person.email,
      phoneNumber: person.phoneNumber,
      designation: person.designation,
      _id: person._id,
    });
  };

  useEffect(() => {
    if (reloadMsg) {
      refetch();
    }
  }, [reloadMsg, refetch]);

  useEffect(() => {
    if (data) {
      console.log("result", data);
      const filteredMessages = data?.getMessages?.filter(
        (message) =>
          message.body.toLowerCase().includes(searchTerm.toLowerCase()) ||
          message.senderUser.email
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          message.senderUser.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        // ||
        // `${new Date(message.createdAt).getMonth() + 1}-${new Date(
        //   message.createdAt
        // ).getDate()}-${new Date(message.createdAt).getFullYear()}`.includes(
        //   searchTerm.toLowerCase()
        // )
      );

      setFilteredMessages(filteredMessages);
    }
  }, [data, searchTerm]);

  return (
    <>
      <Container style={{ paddingTop: "10px", maxWidth: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Box className={classes.addPersonInput}>
              <Paper sx={{ border: "none", boxShadow: "none" }}>
                <Box className={classes.messageTop}>
                  <Typography
                    variant="h6"
                    style={{ padding: "10px", borderBottom: "1px solid #ccc" }}
                  >
                    Masjid's Member
                  </Typography>

                  <Box>
                    <DataSaverOnIcon onClick={handleAddOpenModal} />
                  </Box>
                </Box>

                <MasjidPersonsList
                  reload={reload}
                  masjidId={consumerMasjidId}
                  selectedPerson={selectedPerson}
                  selectedPersonHandler={selectedPersonHandler}
                />
              </Paper>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Paper className={classes.messageContainer}>
              <SearchFilter
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
              <ChatWindow messages={filteredMessages} />
              <MessageInput
                onSendMessage={handleSendMessage}
                inputMessage={inputMessage}
                setInputMessage={setInputMessage}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={addEditModalOpen}
        onClose={handleCloseAddEditModal}
        className={classes.dialogBox}
      >
        <DialogTitle>{isEditing ? "Edit Person" : "Add Person"}</DialogTitle>

        <DialogContent>
          <TextField
            sx={{ border: nameError ? "1px solid red" : "" }}
            autoFocus
            margin="dense"
            label="Person Name"
            fullWidth
            value={personName}
            onChange={(e) => {
              setNameError(false);
              setPersonName(e.target.value);
            }}
          />
          <TextField
            margin="dense"
            label="Email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Phone Number"
            fullWidth
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Designation"
            fullWidth
            value={designation}
            onChange={(e) => setDesignation(e.target.value)}
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              if (isEditing) {
                setIsEditing(false);
                setAddEditModalOpen(false);
                setViewModalOpen(true);
                setPersonName(selectedPerson.personName);
                setPhoneNumber(selectedPerson.phoneNumber);
                setDesignation(selectedPerson.designation);
                setEmail(selectedPerson.email);
              } else {
                setAddEditModalOpen(false);
                // setViewModalOpen(true);
              }
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleSavePerson} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={viewModalOpen}
        onClose={handleCloseViewModal}
        className={classes.dialogBox}
      >
        <DialogTitle>Member Details</DialogTitle>

        <DialogContent className={classes.personDetails}>
          <Grid container xs={12}>
            <Grid item xs={6}>
              <div>
                <h5>Name</h5>
                <p>{selectedPerson.personName}</p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <h5>Email</h5>
                <p>{selectedPerson.email}</p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <h5>Phone Number</h5>
                <p>{selectedPerson.phoneNumber}</p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <h5>Designation</h5>
                <p>{selectedPerson.designation}</p>
              </div>
            </Grid>
          </Grid>
          <IconButton
            style={{ position: "absolute", top: "5px", right: "5px" }}
            onClick={handleEditModalOpen}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CRM;
