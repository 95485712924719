import React from "react";
import SideBar from "../../../Components/FeedComponents/SideBar/SideBar";

import FreezeAdminTable from "../../../Components/FeedComponents/FreezeAdminTable/FreezeAdminTable";
import PageContainer from "../../Shared/PageContainer/PageContainer";

const FreezeAdmins = () => {
  return (
    <>
      <PageContainer pt={"50px"} title="Freezed Admins">
        <div className="RightFeedContainer">
          <FreezeAdminTable />
        </div>
      </PageContainer>
    </>
  );
};

export default FreezeAdmins;
