// import { AUTH_LOGOUT } from '../../ActionType';
// import * as api from '../../../APICalls/index';
// import {Redirect }from  'react-router-dom';

export const authLogout = () => async (dispatch: any) => {
  try {
    dispatch({ type: "AUTH_LOGOUT" });
    //   navigate('/login');
  } catch (error) {
    // (error);
  }
};
