import React from "react";
import moment from "moment";
import { GrMapLocation } from "react-icons/gr";
import { Link } from "react-router-dom";
import "../EventsCarousel/EventCarousel.css";
import { EventType, Masjid } from "../../../../Types";
interface Props {
  item: EventType;
  masjid: Masjid[];
}
const EventsCard: React.FC<Props> = ({ item, masjid }) => {
  const OpenGoogleMaps = (Latitude: number, Longitude: number) => {
    window.open(`https://maps.google.com?q=${Latitude},${Longitude}`);
  };

  return (
    <>
      <div className="cardTop">
        <Link
          // target="blank"
          to={"#"}
          style={{ textDecoration: "none" }}
        >
          <span className="cardTitle"> {item.eventName}</span>
        </Link>
        <span className="timings">
          {" "}
          {moment(item.metaData.startDate).format("DD/MM/YYYY")} -{" "}
          {moment(item.metaData.endDate).format("DD/MM/YYYY")}
        </span>
        <span className="timings">
          {" "}
          {moment(item.timings[0].startTime * 1000).format("hh:mm A")} -{" "}
          {moment(item.timings[0].endTime * 1000).format("hh:mm A")}
        </span>
        <span>{item.isCancelled && "Event Cancelled"}</span>
        <div className="EventsLocation">
          <span className="locTitle">Location: </span>
          <span className="masjidTitle"> {masjid[0]?.masjidName} </span>
          <span className="masjidTitle">
            <GrMapLocation
              size={"1rem"}
              style={{ cursor: "pointer" }}
              onClick={(e) =>
                OpenGoogleMaps(
                  item.location.coordinates[0],
                  item.location.coordinates[1]
                )
              }
            />{" "}
          </span>
        </div>
        <span className="addressTitle"> {item.description} </span>
      </div>
    </>
  );
};

export default EventsCard;
