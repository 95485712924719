import * as api from '../../../APICalls/index';
// import {Redirect }from  'react-router-dom';
import jwt_decode from "jwt-decode";
import {ChangeSnackbar} from '../SnackbarActions/SnackbarActions';
import { Dispatch } from 'redux';


export const forgotPassword = (formData:{email:string},navigate:any) => async(dispatch:Dispatch) => {

    try{  
      const response = await api.ForgotPassword(formData);
      
      if (response.status === 200) {

         let result = {
            data:response.data,
            success:true,
            message:'Email Sent Successfully'
         }
    
         return result;
      }
      return response.data;
    }    
    catch(error:any)
   {
      
      let result = {
         success: false,
         error: `Failed to Login`,
         message: `Failed to Send Email`,
       };

       return result;

   }
}