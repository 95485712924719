import { combineReducers } from "redux";
import FetchMasjidReducer from "./MasjidReducers/FetchMasjidReducer";
import FetchStaleMasjidReducer from "./MasjidReducers/FetchStaleMasjidReducer";
import FetchMasjidByNameReducer from "./MasjidReducers/FetchMasjidByNameReducer";
import FetchMasjidsByAdminReducer from "./MasjidReducers/FetchMasjidsByAdminReducer";
import FetchLatestEventsReducer from "./EventReducers/FetchLatestEventsReducer";
import LoginReducer from "./AuthReducers/LoginReducer";
import SidebarOpenReducer from "./SidebarReducer/SidebarOpenReducer";
import ChangeSnackbarReducer from "./SnackbarReducer/ChangeSnackbarReducer";
import FetchingAdminReducer from "./AdminReducers/FetchingAdminReducer";
import FetchSingleMasjidReducer from "./MasjidReducers/FetchSingleMasjidReducer";

// import FetchMasjidEventReducer from "./EventReducers/FetchMasjidEventReducer";
// import FetchingAdminByNameReducer from "./AdminReducers/FetchingAdminByNameReducer";
// import FetchEventReducer from "./EventReducers/FetchEventReducer";
// import FetchLatestEventsByAdminReducer from './EventReducers/FetchLatestEventsByAdminReducer';
// import AddMasjidReducer from './MasjidReducers/AddMasjidReducer';
// import AddTimingReducer from './TimingReducers/AddTimingReducer';
// import UpdateMasjidReducer from './MasjidReducers/UpdateMasjidReducer';
// import UpdateEventReducer from "./EventReducers/UpdateEventReducer";
// import UpdateNamazReducer from "./TimingReducers/UpdateNamazReducer";
// import FetchingTimingByMasjidReducer from "./TimingReducers/FetchingTimingByMasjidReducer";
// import UpdateTimingReducer from "./TimingReducers/UpdateTimingReducer";
// import FetchingTimingsByDateReducer from "./TimingReducers/FetchingTimingsByDateReducer";

const indexReducer = combineReducers({
  masjids: FetchMasjidReducer,
  singleMasjid: FetchSingleMasjidReducer,
  staleMasjids: FetchStaleMasjidReducer,
  AdminMasjids: FetchMasjidsByAdminReducer,
  LatestEvents: FetchLatestEventsReducer,
  admin: LoginReducer,
  masjid: FetchMasjidByNameReducer,
  sidebarState: SidebarOpenReducer,
  snackBarState: ChangeSnackbarReducer,
  allAdmins: FetchingAdminReducer,

  // masjidEvents: FetchMasjidEventReducer,
  // currentAdmin: FetchingAdminByNameReducer,
  // events: FetchEventReducer,
  // updatedMasjid:UpdateMasjidReducer,
  // LatestAdminEvents:FetchLatestEventsByAdminReducer,
  // newMasjid:AddMasjidReducer,
  // masjidTime:AddTimingReducer,
  // updatedEvent: UpdateEventReducer,

  // masjidTimings: FetchingTimingByMasjidReducer,
  // updatedNamaaz: UpdateNamazReducer,
  // updatedTiming: UpdateTimingReducer,
  // dateTimings: FetchingTimingsByDateReducer,
});

export default indexReducer;
