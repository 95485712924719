import { FETCH_ADMIN_BY_NAME } from '../../ActionType';
import * as api from '../../../APICalls/index';
import {ChangeSnackbar} from '../SnackbarActions/SnackbarActions'
import {handleSnackbar} from '../../../helpers/SnackbarHelper/SnackbarHelper';
import { Dispatch } from 'redux';


export const FetchingAdminByName = (adminName:string) => async(dispatch:Dispatch) => {

    try{

      const response = await api.getAdminById(adminName);

       if(response.status === 200) 
        {
        //  dispatch({type:"FETCH_ADMIN_BY_NAME" , payload: data.data});

        let result = {
          data :response.data,
          success:true
        }

        return result;
       }
       return response.data;
      

    }    
    catch(error:any)
   {
    handleSnackbar(true,"error",`Fetching Admin Failed: ${error.response.data.message}`,dispatch)
    return error.response.data;


   }
}