import { Typography } from "@mui/material";
import moment from "moment";
import React from "react";

const DetailFields = ({ requestDetails, status }: any) => {
  return (
    <>
      {status !== "pending" ? (
        <>
          <Typography variant="subtitle2">Account Status</Typography>
          <Typography
            variant="body2"
            sx={{
              color: status === "approved" ? "#00c986" : "#fc4a4a",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            <div
              style={{
                marginTop: "14px",
                display: "inline-block",
                marginRight: "10px",
              }}
              className={
                status === "approved" ? "green-dot-veri" : "red-dot-veri"
              }
            ></div>
            {requestDetails?.status ?? ""}
          </Typography>
        </>
      ) : null}

      <Typography variant="subtitle2" sx={{ mt: 2 }}>
        Business Name
      </Typography>
      <Typography variant="body2" sx={{ color: "#1B8368", fontWeight: "bold" }}>
        {requestDetails?.stripeDetails?.businessName ?? ""}
      </Typography>

      {/* {status === "pending" ? (
        <> */}
      <Typography variant="subtitle2" sx={{ mt: 2 }}>
        Admin Email Address
      </Typography>
      <Typography variant="body2" sx={{ color: "#1B8368", fontWeight: "bold" }}>
        {requestDetails?.adminEmail ?? ""}
      </Typography>

      <Typography variant="subtitle2" sx={{ mt: 2 }}>
        Stripe Email Address
      </Typography>
      <Typography variant="body2" sx={{ color: "#1B8368", fontWeight: "bold" }}>
        {requestDetails?.stripeDetails?.email ?? ""}
      </Typography>
      {/* </>
      ) : (
        <>
          <Typography variant="subtitle2" sx={{ mt: 2 }}>
            Email Address
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "#1B8368", fontWeight: "bold" }}
          >
            {requestDetails?.stripeDetails?.email ?? ""}
          </Typography>
        </>
      )} */}

      <Typography variant="subtitle2" sx={{ mt: 2 }}>
        Contact Number
      </Typography>
      <Typography variant="body2" sx={{ color: "#1B8368", fontWeight: "bold" }}>
        {requestDetails?.stripeDetails?.contact ?? ""}
      </Typography>

      <Typography variant="subtitle2" sx={{ mt: 2 }}>
        Admin Name
      </Typography>
      <Typography variant="body2" sx={{ color: "#1B8368", fontWeight: "bold" }}>
        {requestDetails?.adminName ?? ""}
      </Typography>

      <Typography variant="subtitle2" sx={{ mt: 2 }}>
        Masjid Name
      </Typography>
      <Typography variant="body2" sx={{ color: "#1B8368", fontWeight: "bold" }}>
        {requestDetails?.masjidName ?? ""}
      </Typography>

      <Typography variant="subtitle2" sx={{ mt: 2 }}>
        Stripe Account Registered with
      </Typography>
      <Typography variant="body2" sx={{ color: "#1B8368", fontWeight: "bold" }}>
        {requestDetails?.stripeDetails?.accountOwnerName ?? ""}
      </Typography>
      {(status === "approved" || status === "rejected") && (
        <>
          <Typography
            variant="subtitle2"
            sx={{ mt: 2, textTransform: "capitalize" }}
          >
            {status} By
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "#1B8368", fontWeight: "bold" }}
          >
            {requestDetails.lastLedger.updatedByUser}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ mt: 2, textTransform: "capitalize" }}
          >
            {status} On
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "#1B8368", fontWeight: "bold" }}
          >
            {moment
              .utc(requestDetails.lastLedger.createdAt)
              .local()
              .format("DD MMM, YYYY | hh:mm A")}
          </Typography>
        </>
      )}
    </>
  );
};

export default DetailFields;
