import { DELETE_MASJID } from "../../ActionType";
import * as api from "../../../APICalls/index";
import { Dispatch } from "redux";
import { statusHandler } from "../../../helpers/HelperFunction";

type formDataType = {
  userId: string | undefined;
  isFreeze: boolean;
};
export const FreezeAdmin =
  (upload: formDataType) => async (dispatch: Dispatch) => {
    try {
      let { data, status } = await api.freezeAdmin(upload);
       data = { ...data, ...statusHandler(status) };
      if (data.success) {
        return data;
      }
      return data;
    } catch (error: any) {
      return error.response.data;
    }
  };
