import React, { useState } from "react";
import {
  Button,
  Modal,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
  Box,
  Typography,
  Grid,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import * as api from "../../APICalls/index";
import FileCopyIcon from "@mui/icons-material/FileCopy";

import CancelIcon from "@mui/icons-material/Cancel";
type propsType = {
  latitude: number | undefined;
  longitude: number | undefined;
  MasjidName: string| undefined;
  masjidId: string;
};
const WidgetComponent: React.FC<propsType> = ({
  latitude,
  longitude,
  MasjidName,
  masjidId,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [assetType, setSelectedAssetType] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const baseURL = import.meta.env.VITE_WIDGET_BASEURL;
  const [tokens, setTokens] = useState<{ [key: string]: string }>({
    event: "",
    notification: "",
    timetable: "",
  });

  const handleGenerateWidget = async () => {
    if (!assetType) {
      toast.error("Please select an asset type");
      return;
    }
    setLoading(true);
    try {
      // Replace this with your actual API endpoint
      const response = await api.createWidget(assetType, masjidId ?? "");
      if (response.status !== 200) {
        throw new Error("Failed to fetch widget data");
      }
      const data = await response.data.data;

      setTokens((prevTokens) => ({
        ...prevTokens,
        [assetType]: data.apiKey,
      }));
      setOpenModal(true);
    } catch (error) {
      console.error("Error fetching widget data:", error);
      toast.error("Failed to generate widget");
    } finally {
      setLoading(false);
    }
  };
  const iFrameSetter = () => {
    const modifiedPath: { [key: string]: string } = {
      event: "events",
      notification: "announcement",
      timetable: "prayer-timing",
    };
    const latLong =
      assetType === "timetable" ? `?lat=${latitude}&lon=${longitude}&` : "?";
    const copyableUrl = `${baseURL}/widget/${
      modifiedPath[assetType] + latLong
    }token=${tokens[assetType]}`;
    const frameCode = ` <iframe title="${
      modifiedPath[assetType]
    }  Widget Of ${MasjidName}"  src="${copyableUrl}"  width="${
      assetType === "event" ? 375 : 380
    }" height="640"scrolling="no"></iframe>`;

    return frameCode;
  };

  const handleCopyToClipboard = () => {
    if (!assetType) {
      toast.error("Asset type must be selected");
      return;
    }
    if (!tokens[assetType]) {
      toast.error(`${assetType} widget not generated.`);
      return;
    }

    navigator.clipboard
      .writeText(iFrameSetter())
      .then(() => {
        toast.success("Code copied to clipboard");
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
        toast.error("Failed to copy code to clipboard");
      });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedAssetType("");
  };

  return (
    <div>
      <Button
        style={{
          border: "1px solid green",
          color: "green",
          height: "20px",
          fontSize: "10px",
          marginLeft: "10px",
          marginBottom: "10px",
          cursor: "Pointer",
        }}
        onClick={() => setOpenModal(true)}
      >
        Generate Widget
      </Button>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            width: "400px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 8,
            p: 4,
          }}
        >
          <Grid container justifyContent="space-between">
            <Typography variant="h6" gutterBottom>
              Select Asset Type
            </Typography>

            <IconButton aria-label="cancel" onClick={handleCloseModal}>
              <CancelIcon />
            </IconButton>
            <Select
              value={assetType}
              id="widget-dropdown"
              onChange={(e) => setSelectedAssetType(e.target.value as string)}
              placeholder="Select Asset Type"
              style={{ marginBottom: "20px" }}
              fullWidth
            >
              <MenuItem id="event-id" value="event">Event</MenuItem>
              <MenuItem value="notification">Notification</MenuItem>
              <MenuItem value="timetable">Prayer Timing</MenuItem>
            </Select>
          </Grid>
          <Button
            variant="contained"
            id="widget-btn"
            onClick={handleGenerateWidget}
            disabled={loading}
            fullWidth
          >
            {loading ? <CircularProgress size={24} /> : "Generate Widget"}
          </Button>
          <br />
          <br />
          <Box display="flex" justifyContent="center">
            <IconButton
              aria-label="copy"
              onClick={handleCopyToClipboard}
              style={{ padding: "8px", color: "#3f51b5" }}
            >
              <FileCopyIcon />
              <Typography variant="body2" style={{ marginLeft: "5px" }}>
                Copy
              </Typography>
            </IconButton>
          </Box>

          <Typography variant="body2" textAlign="center">
            {tokens[assetType]
              ? (baseURL + "/" + assetType).slice(0, 40) + "......."
              : ""}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default WidgetComponent;
