import * as api from "../../../APICalls/index";
import { handleSnackbar } from "../../../helpers/SnackbarHelper/SnackbarHelper";

export const FetchingLatestUpdatedEvents = () => async (dispatch:any) => {
  try {
    let limit = 10;
    let page = 1;
    const response = await api.fetchLatestUpdatedEvents(limit,page);
    if (response.status === 200) {
      dispatch({
        type: "FETCH_LATEST_UPDATED_EVENTS",
        payload: response.data.data,
      });

      return response.data;
    }
    return response.data;
  } catch (error:any) {
    handleSnackbar(
      true,
      "error",
      `Fetching Events Failed: ${error.response.data.message}`,
      dispatch
    );
    return error.response.data;
  }
};
