import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import { useAppThunkDispatch } from "../../Redux/hooks";
import { FreezeMasjidStatus } from "../../Redux/Actions/MasjidActions/FreezingMasjid";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const FreezeMasjid = ({ masjidId }: { masjidId: string }) => {
  const [isFreezing, setisFreezing] = useState(false);
  const [FreezeModalOpen, setFreezeModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppThunkDispatch();
  const handleFreezeMasjid = () => {
    setisFreezing(true);
    if (masjidId) {
      const response = dispatch(FreezeMasjidStatus(masjidId));

      response.then(function (result: any) {
        if (result.success) {
          toast.success("Freezed The masjid Successfully");
          setisFreezing(false);
          setFreezeModalOpen(false);
          navigate("/freezeMasjids");
        } else {
          setisFreezing(false);
          setFreezeModalOpen(false);
          toast.error(`Couldn't update the Masjid Profile :${result.message}`);
        }
      });
    }
  };
  return (
    <>
      <Dialog
        open={FreezeModalOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure you want to freeze the masjid ?`}
        </DialogTitle>
        <DialogActions style={{ justifyContent: "space-around" }}>
          <Button
            onClick={() => setFreezeModalOpen(false)}
            variant="outlined"
            disabled={isFreezing}
          >
            No
          </Button>

          <Button onClick={handleFreezeMasjid} variant="outlined">
            {" "}
            {isFreezing ? (
              <CircularProgress
                // color="black"
                size="15px"
              />
            ) : (
              <span> Yes </span>
            )}{" "}
          </Button>
        </DialogActions>
      </Dialog>

      <Button
        onClick={(e) => setFreezeModalOpen(true)}
        style={{
          border: "1px solid red",
          color: "red",
          height: "20px",
          fontSize: "10px",
          marginLeft: "10px",
          marginBottom: "10px",
          cursor: "Pointer",
        }}
      >
        Freeze Masjid
      </Button>
    </>
  );
};

export default FreezeMasjid;
