import { FETCH_MASJID_BY_NAME } from "../../ActionType";
import { Action, Masjid } from "../../../../Types";

const FetchMasjidByNameReducer = (
  masjid: Masjid[] = [],
  action: Action
): Masjid[] => {
  switch (action.type) {
    case FETCH_MASJID_BY_NAME:
      return action.payload;

    default:
      return masjid;
  }
};

export default FetchMasjidByNameReducer;
