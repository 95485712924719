// src/graphql/queries.ts
import { gql } from "@apollo/client";

export const GET_MEMBERS = gql`
  query GetMembers($masjidId: ID!) {
    getMembers(masjidId: $masjidId) {
      _id
      personName
      email
      phoneNumber
      designation
    }
  }
`;
export const GetUsers = gql`
  query GetUsers($limit: Int) {
    users(limit: $limit) {
      _id
      name
      masjidDetails {
        _id
        masjidName
        location {
          coordinates
        }
      }
      email
      role
      autoPrefillingTiming
      isVerified
      profilePhoto
      isVerified
      isFreezed
      createdAt
      updatedAt
    }
  }
`;

export const GET_MESSAGES = gql`
  query GetMessages($masjidId: String!) {
    getMessages(masjidId: $masjidId) {
      _id
      body
      senderUser {
        _id
        name
        email
      }
      createdAt
    }
  }
`;
