import { AUTH_LOGIN } from "../../ActionType";
import * as api from "../../../APICalls/index";
// import {Redirect }from  'react-router-dom';
import jwt_decode from "jwt-decode";

export const ResettingGoogleAuth = (email: any) => async () => {
  try {
    const response = await api.ResetGoogleAuth(email);

    if (response.status === 200) {
      let result = {
        data: response.data.data,
        message: response.data.data.message,
        success: true,
      };

      return result;
    }
    return response.data.data;
  } catch (error: any) {
    if (error.response?.data) {
      let result = {
        success: false,
        error: `Failed to Reset`,
        message: error.response?.data?.data?.error,
      };
      return result;
    }
    return error.response;
  }
};
