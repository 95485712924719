import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, Grid, TextField } from "@mui/material";

interface MessageInputProps {
  onSendMessage: () => void;
  inputMessage: string;
  setInputMessage: Dispatch<SetStateAction<string>>;
}

const MessageInput: React.FC<MessageInputProps> = ({
  onSendMessage,
  setInputMessage,
  inputMessage,
}) => {
  const handleSendMessage = () => {
    if (inputMessage.trim() !== "") {
      onSendMessage();
    }
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={9}>
        <TextField
          variant="outlined"
          placeholder="Type your message..."
          fullWidth
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
        />
      </Grid>
      <Grid item xs={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendMessage}
          style={{ height: "100%", width: "100%" }}
          disabled={inputMessage.trim() === ""}
        >
          Send
        </Button>
      </Grid>
    </Grid>
  );
};

export default MessageInput;
