import { InputAdornment, styled, TextField } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

const CustomTextField = styled(TextField)(({ theme }) => ({
  width: "95%",
  margin: "16px",
  [theme.breakpoints.down("sm")]: {
    margin: "8px ",
    fontSize: "0.8rem",
  },
  "& .MuiOutlinedInput-root": {
    // padding: "4px",
    borderRadius: "8px",
    border: "1px solid #606060", // Border for the text field
    paddingLeft: "5px",
    padding: "4px",
    "& fieldset": {
      borderColor: "transparent", // Remove default border
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
  },
  "& .MuiInputAdornment-root": {
    marginRight: "4px",
  },
  "& .MuiInputBase-input": {
    padding: "4px 4px",
    fontSize: "0.8rem",
  },
}));
interface SearchFilterProps {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}
const SearchFilter = ({ searchTerm, setSearchTerm }: SearchFilterProps) => {
  return (
    <CustomTextField
      variant="outlined"
      fullWidth
      placeholder="Search"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{ fontSize: "22px" }} />
          </InputAdornment>
        ),
      }}
      sx={{ padding: "4px" }}
    />
  );
};

export default SearchFilter;
