import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppThunkDispatch } from "../../../Redux/hooks";
import PageContainer from "../../Shared/PageContainer/PageContainer";
import { masjidIdSetter } from "../../../Redux/Actions/AuthActions/MasjidIdSetterAction";

const MasjidProfile = () => {
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();
  const { masjidId } = useParams();

  sessionStorage.setItem("masjidId", masjidId ? masjidId : "");
  const [checked, setChecked] = useState(true);

  if (checked && masjidId) {
    const res = dispatch(masjidIdSetter(masjidId));
    res.then(() => {
      navigate("/feed/2");
    });
  } else
    return <PageContainer title="Vanila portal">Loading....</PageContainer>;
};
export default MasjidProfile;
