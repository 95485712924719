import React, { useState, ReactNode, Dispatch, SetStateAction } from "react";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@mui/styles";

import { Avatar, Chip, IconButton, InputAdornment } from "@mui/material";
import { ReceivedEmailObject } from "../../../../Types";

interface EmailInputProps {
  emails: ReceivedEmailObject[];
  setEmails: Dispatch<SetStateAction<ReceivedEmailObject[]>>;
}
const EmailChip: React.FC<EmailInputProps> = ({ emails, setEmails }) => {
  const [inputValue, setInputValue] = useState<string>("");

  const handleAddEmail = () => {
    if (inputValue.trim() !== "") {
      setEmails([...emails, { name: "ReceivedEmail", url: inputValue.trim() }]);
      setInputValue("");
    }
  };

  const handleRemoveEmail = (emailToRemove: string) => {
    const updatedEmails = emails.filter((email) => email.url !== emailToRemove);
    setEmails(updatedEmails);
  };

  return (
    <div
      style={{
        border: "1px solid #c4c4c4",
        marginTop: "5px",
        paddingBottom: "5px",
        borderRadius: "4px",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        mt={1}
        sx={{ flexWrap: "wrap" }}
      >
        {emails.map((email, index) => (
          <Chip
            key={index}
            avatar={<Avatar alt={email?.url} src="" />}
            label={email?.url}
            color="primary"
            variant="outlined"
            onDelete={() => handleRemoveEmail(email.url)}
            deleteIcon={<ClearIcon />}
          />
        ))}
        <TextField
          //   variant="standard"
          label=""
          id="Received-Email-Address"
          placeholder="Received Email Address"
          value={inputValue}
          rows={2}
          sx={{ border: "none" }}
          variant="standard"
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={handleAddEmail}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleAddEmail}
                  edge="end"
                  disabled={inputValue.trim() === ""}
                >
                  <CheckIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </div>
  );
};

export default EmailChip;
