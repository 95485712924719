import React, { useRef, useState } from "react";
import "./ResetPassword.css";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../../../Redux/Actions/AuthActions/ResetPasswordAction";
import { useAppThunkDispatch } from "../../../Redux/hooks";
import PasswordInput from "../../Shared/PasswordInput/PasswordInput";
import AuthActionBtn from "../../Shared/AuthActionBtn/AuthActionBtn";
import AuthLeft from "../../Shared/AuthLeft/AuthLeft";
import { toast } from "react-hot-toast";

const ResetPassword = () => {
  const navigate = useNavigate();
  const password = useRef<HTMLInputElement | null>(null);
  const confirmPassword = useRef<HTMLInputElement | null>(null);
  const dispatch = useAppThunkDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [showPas, setShowPas] = useState(false);
  const [showConPas, setShowConPas] = useState(false);
  const { token } = useParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password.current?.value === confirmPassword.current?.value) {
      let formData = {
        password: password.current?.value ?? "",
        token: token ?? "",
      };
      setIsFetching(true);
      const res = dispatch(resetPassword(formData));
      res.then((result: any) => {
        if (result.success) {
          navigate("/login");
          toast.success(" Password Setup Successfully");
          setIsFetching(false);
        } else {
          toast.error("Failed To Setup the Password");
          setIsFetching(false);
        }
      });
    } else {
      toast.error("Password and Confirmed Password does not match");
      setIsFetching(false);
    }
  };

  return (
    <div className="ForgotPassword">
      <div className="loginWrapper">
        <AuthLeft />
        <div className="loginRight">
          <div className="loginBox">
            <form onSubmit={handleSubmit} className="ForgotPasswordBox">
              <PasswordInput
                reference={password}
                pHolder={"Password"}
                showPas={showPas}
                setShowPas={setShowPas}
                belowTx={false}
              />
              <PasswordInput
                reference={confirmPassword}
                pHolder={"Confirm Password"}
                showPas={showConPas}
                setShowPas={setShowConPas}
              />
              <AuthActionBtn isFetching={isFetching} />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
