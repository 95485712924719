import React, { Dispatch, SetStateAction, useState } from "react";
// import "./ResetPassword.css";
// import { resetPassword } from "../../../Redux/Actions/AuthActions";
import { useAppThunkDispatch } from "../../../Redux/hooks";
import AuthActionBtn from "../../Shared/AuthActionBtn/AuthActionBtn";
import { toast } from "react-hot-toast";
import { resetPassword } from "../../../Redux/Actions/AuthActions/ResetPasswordAction";
import { Link, useNavigate } from "react-router-dom";
type prosType = {
  setShowOTPSetter: Dispatch<SetStateAction<boolean>>;
  email: string;
};
const OTPSetter = ({ setShowOTPSetter, email }: prosType) => {
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();
  const handleResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsFetching(true);

    // Password validation
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    if (!password.match(passwordRegex)) {
      toast.error(
        "Password must contain at least 8 characters, including at least 1 lowercase character, 1 uppercase character, 1 number, and 1 special character (!@#$%^&*)"
      );
      setIsFetching(false);
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      setIsFetching(false);
      return;
    }

    const res = await dispatch(
      resetPassword({
        email,
        token: otp,
        password,
        type: "otp",
      })
    );
    setIsFetching(false);
    if (res.success) {
      toast.success(
        "Password reset successful. You can now login with your new password."
      );
      navigate("/login");

      // Redirect to login page or any other page as needed
    } else {
      toast.error(
        res?.message ||
          "Failed to reset password. Please verify your OTP and try again."
      );
    }
  };

  return (
    <div className="ResetPassword">
      <form
        onSubmit={handleResetPassword}
        data-testid="reset-password-form"
        className="ForgotPasswordBox"
      >
        <input
          placeholder="OTP"
          type="text"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          required
          className="loginInput"
        />
        <input
          placeholder="New Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="loginInput"
        />
        <input
          placeholder="Confirm Password"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
          className="loginInput"
        />
        <AuthActionBtn isFetching={isFetching}>
          <Link to="" onClick={() => setShowOTPSetter(false)}>
            {" "}
            Back
          </Link>
        </AuthActionBtn>
      </form>
    </div>
  );
};

export default OTPSetter;
