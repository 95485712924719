import { Dispatch } from "redux";
import * as api from "../../../APICalls/index";
import {
  ChangeSnackbar,
  SnackbarAction,
} from "../SnackbarActions/SnackbarActions";
import { statusHandler } from "../../../helpers/HelperFunction";

type MyType = {
  body: string | undefined;
  masjidIds: string[];
  title: string | undefined;
};
export const AnnouncementNotification =
  (formData: MyType) => async (dispatch: Dispatch<any>) => {
    try {
      let { data, status } = await api.triggeringAnnouncement(formData);

      data = { ...data, ...statusHandler(status) };

      if (data.success) {
        const snackbarDetails = {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Sent the Notifications to all the Masjid Users",
        };
        dispatch(ChangeSnackbar(snackbarDetails));
        return data;
      }
      return data;
    } catch (error) {
  
      const snackbarDetails = {
        snackbarOpen: true,
        snackbarType: "error",
        snackbarMessage: "Failed to Send the Notification to the Application",
      };
      dispatch(ChangeSnackbar(snackbarDetails));

      return error;
    }
  };
