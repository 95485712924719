// import { FETCH_STALE_MASJIDS } from "../../ActionType";
import { Dispatch } from "redux";
import * as api from "../../../APICalls/index";
// import { ChangeSnackbar } from "../SnackbarActions/SnackbarActions";
import { handleSnackbar } from "../../../helpers/SnackbarHelper/SnackbarHelper";

export const getStaleMasjids =
  (sortParam: string, sortOrder: string) => async (dispatch: Dispatch) => {
    try {
      const response = await api.fetchMasjids(sortParam, sortOrder);

      if (response.status === 200) {
        dispatch({
          type: "FETCH_STALE_MASJIDS",
          payload: response.data.data,
        });
        return response.data;
      }
    } catch (error:any) {
      handleSnackbar(
        true,
        "error",
        `Fetching Events Failed: ${error.response.data.message}`,
        dispatch
      );
      return error.response.data;
    }
  };
