import { Dispatch } from "redux";
import * as api from "../../../APICalls/index";
import { ChangeSnackbar } from "../SnackbarActions/SnackbarActions";
import { statusHandler } from "../../../helpers/HelperFunction";

type updatedDataType = {
  name: string;
  email: string;
};
export const UpdatingAdminById =
  (upadatedData: updatedDataType, adminId: string) =>
  async (dispatch: Dispatch<any>) => {
    try {
      let { data, status } = await api.updateAdminById(upadatedData, adminId);
      data = { ...data, ...statusHandler(status) };
      if (data.success) {
        const snackbarDetails = {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Update Admin",
        };

        dispatch(ChangeSnackbar(snackbarDetails));
        return data;
      }
      return data;
    } catch (error: any) {
      const snackbarFailureDetails = {
        snackbarOpen: true,
        snackbarType: "error",
        snackbarMessage: error.response.data
          ? error.response.data.message
          : "Updating Admin Failed",
      };

      dispatch(ChangeSnackbar(snackbarFailureDetails));
    }
  };
