import { FETCH_MASJID, FETCH_MASJID_BY_NAME } from "../../ActionType";
import * as API from "../../../ClientAPICalls/index";
import { ChangeSnackbar } from "../SnackbarActions/SnackbarActions";
import { Dispatch } from "redux";

export const FetchMasjidById =
  (id: string) => async (dispatch: Dispatch<any>) => {
    try {
      const { data } = await API.fetchMasjidById(id);
      if (data.success) {
        dispatch({ type: FETCH_MASJID, payload: [data.data] });
        return data;
      }
      return data;
    } catch (error: any) {
      const snackbarFailureDetails = {
        snackbarOpen: true,
        snackbarType: "error",
        snackbarMessage: "Fetching Masjid Failed",
      };

      dispatch(ChangeSnackbar(snackbarFailureDetails));

      return error.response;
    }
  };
