import React, { useRef, useState } from "react";
import "./ForgotPassword.css";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../../Redux/Actions/AuthActions/ForgotPasswordAction";
import { useAppThunkDispatch } from "../../../Redux/hooks";
import AuthLeft from "../../Shared/AuthLeft/AuthLeft";
import AuthActionBtn from "../../Shared/AuthActionBtn/AuthActionBtn";
import { toast } from "react-hot-toast";
import OTPSetter from "./OTPSetter";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const email = useRef<HTMLInputElement | null>(null);
  const dispatch = useAppThunkDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [showOTPSetter, setShowOTPSetter] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setIsFetching(true);
    e.preventDefault();
    const res = dispatch(
      forgotPassword({ email: email.current?.value ?? "" }, navigate)
    );
    res.then((result) => {
      if (result.success) {
        toast.success(`Email Sent Successfully`);
        setIsFetching(false);
        setShowOTPSetter(true);
      } else if (!result.success) {
        toast.error("Failed To Reset Password");
        setIsFetching(false);
      }
    });
  };

  return (
    <div className="ForgotPassword">
      <div className="loginWrapper">
        <AuthLeft />
        <div className="loginRight">
          <div className="loginBox">
            {showOTPSetter ? (
              <OTPSetter setShowOTPSetter={setShowOTPSetter} email={email.current?.value??""} />
            ) : (
              <form onSubmit={handleSubmit} className="ForgotPasswordBox">
                <input
                  placeholder="Email"
                  type="email"
                  ref={email}
                  required
                  className="loginInput"
                />
                <AuthActionBtn isFetching={isFetching} ForgotPas={true} />
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
